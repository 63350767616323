import React, { useEffect, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import AgreementsTable from './AgreementsTable';
import AgreementsDrawer from './AgreementsDrawer';
import { AGREEMENTS_TOOLTIP } from 'constants/tooltip';
import { getRequest } from 'api/apiClient';
import { Agreement } from 'types/agreementTypes';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

const Agreements: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [agreements, setAgreements] = useState<Agreement[]>([]);
    const [selectedAgreement, setSelectedAgreement] = useState<Agreement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const agreements = await getRequest(`/orgs/${orgId}/termsAgreements`);
                const filteredAgreements = agreements.data.filter((agreement: Agreement) => agreement.status === 1);
                setAgreements(filteredAgreements);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    const handleEditClick = (agreement: Agreement) => {
        setSelectedAgreement(agreement);
        setDrawerOpen(true);
    };

    const handleAddClick = () => {
        setSelectedAgreement(null);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedAgreement(null);
    };

    return (
        <OpPage title="Agreements" tooltip={AGREEMENTS_TOOLTIP}>
            <AgreementsTable
                agreements={agreements}
                loading={loading}
                setAgreements={setAgreements}
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
            />
            <AgreementsDrawer
                open={drawerOpen}
                selectedAgreement={selectedAgreement}
                onClose={handleDrawerClose}
                setAgreements={setAgreements}
            />
        </OpPage>
    );
}

export default Agreements;
