import React, { useState } from 'react';
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Button, Modal, Input, Checkbox, message } from 'antd';
import { hasPermission } from 'utils/utils';
import { RedFlagData } from 'types/redFlagTypes';

const { Group: CheckboxGroup } = Checkbox;

interface DateFormProps {
    redFlagData: RedFlagData[];
}

const DateForm: React.FC<DateFormProps> = ({ redFlagData }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const [openSelectFieldsModal, setOpenSelectFieldsModal] = useState(false);
    const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [customFieldLabel, setCustomFieldLabel] = useState('');
    const [customFields, setCustomFields] = useState<{ id: number, label: string, value: string }[]>([]);

    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    const handleSelectFieldsModalOk = () => {
        const nonCustomOptions = selectedFields.filter(option => option !== 'custom');

        if (nonCustomOptions.length > 0) {
            const newFields = nonCustomOptions.map(option => ({
                id: Date.now() + Math.random(),
                label: option,
                value: ""
            }));

            setCustomFields(prevFields => [...prevFields, ...newFields]);
        }

        if (selectedFields.includes('custom')) {
            setOpenCustomFieldModal(true);
        }

        setSelectedFields([]);
        setOpenSelectFieldsModal(false);
    };

    const handleCustomModalOk = () => {
        if (!customFieldLabel.trim()) {
            message.warning("Custom field name cannot be empty.");
            return;
        }

        setCustomFields(prevFields => [
            ...prevFields,
            { id: Date.now() + Math.random(), label: customFieldLabel, value: "" }
        ]);

        setCustomFieldLabel('');
        setOpenCustomFieldModal(false);
    };

    const availableOptions = [
        'Address',
        'City',
        'State',
        'Alias',
        'Phone Number',
        'ID Type',
        'ID Number'
    ];

    return (
        <>
            {redFlagData && redFlagData.map((item) => (
                <OpForm.Input
                    key={`${item.data}_${item.id}`} // Use the same key as in initialValues
                    label={item.data}
                    name={`${item.data}_${item.id}`} // Use the same name as in initialValues
                />
            ))}
            {customFields.map((field) => (
                <OpForm.Input
                    key={`${field.label}_${field.id}`} // Keep the key consistent
                    label={field.label}
                    name={`${field.label}_${field.id}`} // Keep the name consistent
                />
            ))}
            {hasRedflagWrite && (
                <Button type="dashed" onClick={() => {
                    setOpenSelectFieldsModal(true);
                }}>
                    Add Custom Field
                </Button>
            )}

            <Modal title="Select Fields" open={openSelectFieldsModal} onOk={handleSelectFieldsModalOk} onCancel={() => {
                setSelectedFields([]);
                setOpenSelectFieldsModal(false);
            }} width={300} centered>
                <CheckboxGroup
                    onChange={(checkedValues: any) => {
                        setSelectedFields(checkedValues);
                    }}
                    value={selectedFields}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                >
                    {availableOptions.map(option => (
                        <Checkbox key={option} value={option} style={{ marginBottom: '8px' }}>
                            {option}
                        </Checkbox>
                    ))}
                    <Checkbox value="custom" style={{ marginBottom: '8px' }}>Custom</Checkbox>
                </CheckboxGroup>
            </Modal>

            <Modal title="Enter Custom Field Name" open={openCustomFieldModal} onOk={handleCustomModalOk} onCancel={() => {
                setCustomFieldLabel('');
                setOpenCustomFieldModal(false);
            }} centered>
                <Input
                    placeholder="Enter custom field name"
                    value={customFieldLabel}
                    onChange={(e) => setCustomFieldLabel(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default DateForm;
