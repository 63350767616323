import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";
import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { DATE_FORMAT } from 'constants/dates';


const ActivityLogContent: React.FC = () => {
    return (
        <>
            <OpForm.Input label="Activity" name="activity" />

            <OpForm.Input label="User" name="user" />

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.DatePicker name="date" label="Date" format={DATE_FORMAT} style={{ width: '100%' }} disabled />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.TimePicker name="time" label="Time"
                        format="h:mm A"
                        use12Hours
                        minuteStep={5}
                        needConfirm={false}
                        style={{ width: '100%' }}
                        disabled
                    />
                </OpCol>
            </OpRow>
            <OpForm.TextAreaInput readOnly rows={4} label="Activity Details" name="activityDetails" />
        </ >
    );
};

export default ActivityLogContent;
