import React from 'react';
import { useSelector } from 'react-redux';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { RootState } from 'store/store';
import { notification } from 'antd';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName, hasPermission } from 'utils/utils';
import { Role } from 'types/roleTypes';
import { CONFIRM_DELETE_CONTENT, CONFIRM_DELETE_TITLE, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, ROLE_DELETE_ERROR, ROLE_DELETE_ERROR_ONLY_ROLE, ROLE_DELETE_SUCCESS } from 'constants/messages';
import { deleteRequest, getRequest, postRequest } from 'api/apiClient';
import { User } from 'types/userTypes';
import { ROLE_DELETED_ID } from 'constants/userActivities';

interface RolesTableProps {
    roles: Role[];
    loading: boolean;
    setRoles: (role: Role[]) => void;
    handleEditClick: (role: Role) => void;
    handleAddClick: () => void;
}

const RolesTable: React.FC<RolesTableProps> = ({ roles, loading, setRoles, handleEditClick, handleAddClick }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');

    const handleDeleteRole = async (role: Role) => {
        if (role.admin) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: `The ${role.name} role cannot be deleted.`,
                placement: 'bottomRight',
            });
            return;
        }

        try {
            // Fetch users in the role
            const usersInRoleResponse = await getRequest(`/orgs/${orgId}/roles/${role.id}/users`);
            const usersWithoutOtherRoles = usersInRoleResponse.data.filter((user: User) => Number(user.roleCount) === 1);

            if (usersWithoutOtherRoles.length > 0) {
                const userNames = usersWithoutOtherRoles.map((user: User) =>
                    formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
                ).join(', ');
                notification.error({
                    message: NOTIFICATION_ERROR,
                    description: ROLE_DELETE_ERROR_ONLY_ROLE(userNames),
                    placement: 'bottomRight',
                });
                return;
            }

            await deleteRequest(`/orgs/${orgId}/roles/${role.id}`);
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: ROLE_DELETED_ID,
                details: role.name
            });

            // Re-fetch the updated list of roles
            const updatedRoles = await getRequest(`/orgs/${orgId}/roles`);
            const filteredRoles = updatedRoles.data.filter((role: Role) => role.status === 1);
            setRoles(filteredRoles);

            notification.success({
                message: NOTIFICATION_SUCCESS,
                description: ROLE_DELETE_SUCCESS,
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: ROLE_DELETE_ERROR,
                placement: 'bottomRight',
            });
        }
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: 'ROLE',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        },
        {
            dataIndex: 'description',
            label: 'DESCRIPTION',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.description || '').localeCompare(b.description || ''),
        },
        {
            dataIndex: 'userCount',
            label: 'USER COUNT',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => a.userCount - b.userCount
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: roles,
        columns: columns,
        rowActions: {
            onEditClick: (role: Role) => handleEditClick(role),
            onDeleteClick: hasRoleWrite ? handleDeleteRole : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("Role"),
            deleteModalContent: ({ record }) => CONFIRM_DELETE_CONTENT(record.name),
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasRoleWrite,
        allowAddition: hasRoleWrite ? {
            itemName: 'Role',
            onClick: () => handleAddClick(),
        } : false,
        loading: loading,
        allowExport: true,
        allowShowHideColumns: true,
    };

    return <OpTable {...opTableProps} />;
};

export default RolesTable;
