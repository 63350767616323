import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Input } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthState } from 'store/slices/authSlice';
import { clearGlobalOrgState } from 'store/slices/globalOrgSlice';
import { AppDispatch, RootState } from 'store/store';
import { toggleCollapsed } from 'store/slices/uiSlice';
import { clearUsersState, describeUser } from 'store/slices/usersSlice';
import { formatFullName, hasPermission } from 'utils/utils';
import ProfileDrawer from './profile/ProfileDrawer';
import { clearVisitorSearchState, searchVisitors } from 'store/slices/visitorSearchSlice';
import { clearLocationsState } from 'store/slices/locationsSlice';
import { clearAgreementsState } from 'store/slices/agreementsSlice';
import { clearDenyState } from 'store/slices/denySlice';
import { clearKiosksState } from 'store/slices/kiosksSlice';
import { clearScopeResourcesState } from 'store/slices/scopeResourcesSlice';
import { clearRedFlagState } from 'store/slices/redFlagSlice';
import { clearRolesState } from 'store/slices/rolesSlice';
import { clearUserActivityState } from 'store/slices/userActivitySlice';
import { clearVisitorTypesState } from 'store/slices/visitorTypesSlice';
import { clearVisitorsState } from 'store/slices/visitorsSlice';
import { clearVisitorWorkflowsState } from 'store/slices/visitorWorkflowsSlice';
import { clearVisitsState } from 'store/slices/visitsSlice';
import { clearVisitorInvitationState } from 'store/slices/visitorInvitationSlice';
import { profileIcon } from 'utils/visitorsHelper';
import { OpTooltip } from 'components/customAntd/DLS/OpTooltip/OpTooltip';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { ReactComponent as VoltIcon } from 'images/icons/volt.svg';
import { persistor } from 'store/store';
import QuickActionsModal from './quickActions/QuickActionsModal';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';

const AppHeader: React.FC = () => {

    const { Search } = Input;

    // State hooks
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [quickActionsVisible, setQuickActionsVisible] = useState(false); // State to control QuickActionsModal visibility

    // Navigation hook
    const navigate = useNavigate();

    // Dispatch hook
    const dispatch: AppDispatch = useDispatch();

    // Selector hooks
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { auth } = useSelector((state: RootState) => state.auth);
    const { globalUser } = useSelector((state: RootState) => state.users);
    const { globalLocation } = useSelector((state: RootState) => state.locations);

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');


    // Directly access the updated org name from the Redux auth state
    const selectedOrgName = auth.data[0].tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId))?.org.name;

    const permissions = useMemo(() => ({
        hasDashRead: hasPermission(tokenScopeList, orgId, 'o', 'dash:r'),
        hasVisitorsRead: hasPermission(tokenScopeList, orgId, 'o', 'visitors:r'),
        hasLocationRead: hasPermission(tokenScopeList, orgId, 'o', 'location:r'),
        hasUsersRead: hasPermission(tokenScopeList, orgId, 'o', 'user:r'),
        hasRolesRead: hasPermission(tokenScopeList, orgId, 'o', 'role:r'),
        hasKiosksRead: hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r'),
        hasRedFlagConfRead: hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r'),
        hasInvitationRead: hasPermission(tokenScopeList, orgId, 'o', 'invitation:r'),
        hasWorkflow: hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r') || hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r'),
        hasFieldsRead: hasPermission(tokenScopeList, orgId, 'o', 'fields:r'),
        hasAgreementsRead: hasPermission(tokenScopeList, orgId, 'o', 'agreements:r'),
        hasAccountRead: hasPermission(tokenScopeList, orgId, 'o', 'account:r'),
        hasIntegrationRead: hasPermission(tokenScopeList, orgId, 'o', 'integration:r'),
        hasRptVisitorRead: hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r'),
        hasRptTrendRead: hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r'),
        hasRptRedFlagRead: hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r'),
        hasRptHostRead: hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r'),
        hasRptActivityRead: hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r'),
        hasRptGuestPassRead: hasPermission(tokenScopeList, orgId, 'o', 'rptguestpass:r'),
    }), [tokenScopeList, orgId]);

    const routes = useMemo(() => [
        permissions.hasDashRead && '/dashboard',
        permissions.hasVisitorsRead && '/visitors',
        permissions.hasLocationRead && '/locations',
        permissions.hasUsersRead && '/users/users',
        permissions.hasRolesRead && '/users/roles',
        permissions.hasKiosksRead && '/kiosks',
        permissions.hasRedFlagConfRead && '/redFlag',
        permissions.hasInvitationRead && '/configuration/invitations',
        permissions.hasWorkflow && '/configuration/workflow',
        permissions.hasFieldsRead && '/configuration/formFields',
        permissions.hasAgreementsRead && '/configuration/agreements',
        permissions.hasAccountRead && '/administration/account',
        permissions.hasIntegrationRead && '/administration/integration',
        permissions.hasRptVisitorRead && '/reports/visitorReport',
        permissions.hasRptTrendRead && '/reports/repeatVisitors',
        permissions.hasRptRedFlagRead && '/reports/redFlagReport',
        permissions.hasRptGuestPassRead && '/reports/guestPassReport',
        permissions.hasRptHostRead && '/reports/hostReport',
        permissions.hasRptActivityRead && '/reports/activityLog',
    ].filter(Boolean), [permissions]);

    // Fetch user details only when orgId changes
    useEffect(() => {
        if (orgId && auth.data[0]) {
            const selectedOrg = auth.data[0].tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId));
            if (selectedOrg && selectedOrg.user) {
                const userId = selectedOrg.user.id;
                dispatch(describeUser({ orgId, userId, global: true }));
            }
        }
    }, [orgId, auth.data, dispatch]);

    const userFullName = useMemo(() => {
        if (globalUser && globalUser.identity) {
            return formatFullName(globalUser?.identity.firstName, globalUser?.identity.middleName, globalUser?.identity.lastName);
        }
    }, [globalUser]);

    const createUserAvatarIcon = (size: number, marginRight: number) => {
        const nameOrEmail = userFullName || globalUser?.identity.email || "";
        return profileIcon({
            fullName: nameOrEmail,
            size: size,
            color: isDarkMode ? "#31394b" : "#D3D3D3",
            textColor: isDarkMode ? "#FFFFFF" : "#000000",
            shape: "square",
            marginRight: marginRight,
        });
    };

    // eslint-disable-next-line
    const userAvatarIcon40 = useMemo(() => createUserAvatarIcon(40, 0), [userFullName, globalUser, isDarkMode]);
    // eslint-disable-next-line
    const userAvatarIcon70 = useMemo(() => createUserAvatarIcon(70, 15), [userFullName, globalUser, isDarkMode]);

    const confirmModal = useConfirmModal();

    const handleIconClick = () => {
        if (routes.length > 0) {
            navigate(routes[0], { replace: true });
        } else {
            navigate('/support', { replace: true });
        }
    };

    const onLogout = async () => {
        confirmModal({
            title: 'Log Out',
            content: 'Log out from your account?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                // Clear state
                dispatch(clearAgreementsState());
                dispatch(clearAuthState());
                dispatch(clearDenyState());
                dispatch(clearGlobalOrgState());
                dispatch(clearKiosksState());
                dispatch(clearLocationsState());
                dispatch(clearRedFlagState());
                dispatch(clearRolesState());
                dispatch(clearScopeResourcesState());
                dispatch(clearUserActivityState());
                dispatch(clearVisitorInvitationState());
                dispatch(clearVisitorSearchState());
                dispatch(clearVisitorsState());
                dispatch(clearVisitorTypesState());
                dispatch(clearVisitorWorkflowsState());
                dispatch(clearVisitsState());
                dispatch(clearUsersState());

                // Clear persisted state
                await persistor.purge();

                // Remove token from localStorage
                localStorage.removeItem('authToken');
                localStorage.removeItem('identityId');
                localStorage.removeItem('createdAt');
                localStorage.removeItem('expiresAt');
                localStorage.removeItem('tokenScopeList');

                // Navigate to login page
                navigate('/login');
            },
        });
    };

    const menu = {
        items: [
            {
                key: 'profileHeader',
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px 10px 10px 0', borderBottom: '1px solid #f0f0f0', width: '250px' }}>
                        {userAvatarIcon70}
                        <div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '16px', color: 'var(--colorHeaderButtonText)' }}>{userFullName}</div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '12px', color: 'var(--colorHeaderButtonText)' }}>{globalUser?.identity.email}</div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '12px', color: 'var(--colorHeaderButtonText)' }}>org:{selectedOrgName}</div>
                        </div>
                    </div>
                ),
                disabled: true,
            },
            {
                key: '1',
                label: (
                    <Button type="primary" block onClick={() => setDrawerVisible(true)}>
                        Profile
                    </Button>
                ),
            },
            {
                key: '2',
                label: (
                    <Button block danger onClick={onLogout}>
                        Sign out
                    </Button>
                ),
            },
        ]
    };

    const onSearch = (value: string) => {
        if (value && globalLocation?.id) {
            dispatch(searchVisitors({ orgId, siteId: globalLocation.id, searchString: value })).then(() => {
                navigate('/visitorSearchResults');
            });
        }
    };

    return (
        <div style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: "60px"
        }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '50' }}>
                <Button
                    type="text"
                    icon={<MenuOutlined />}
                    onClick={() => dispatch(toggleCollapsed())}
                    style={{
                        marginLeft: '16px'
                    }}
                />

                <div
                    style={{ display: 'flex', alignItems: 'center', height: '60px', cursor: 'pointer' }}
                    onClick={handleIconClick}
                >
                    <img src="/images/logo_main.png" alt="Logo" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                </div>
            </div>

            <div>
                <Search placeholder="Search Visitor" allowClear style={{ width: 400 }} onSearch={onSearch} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {(hasSigninWrite || hasSignoutWrite || hasInviteWrite) && (
                    <div style={{ marginRight: '16px' }}>
                        <OpTooltip placement="topRight" title="Quick Actions">
                            <span>
                                <OpButton
                                    icon={<VoltIcon style={{ width: 18, height: 18 }} />}
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        backgroundColor: isDarkMode ? "#31394b" : "#D3D3D3",
                                        color: isDarkMode ? "#D3D3D3" : "#31394b"
                                    }}
                                    onClick={() => setQuickActionsVisible(true)}
                                />
                            </span>
                        </OpTooltip>
                    </div>
                )}
                <div style={{ marginRight: '16px' }}>
                    {selectedOrgName}
                </div>
                <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
                    <Button type="text" onClick={e => e.preventDefault()} style={{ padding: 0, marginRight: '16px', height: '40px' }}>
                        {userAvatarIcon40}
                    </Button>
                </Dropdown>
            </div>

            {drawerVisible && (
                <ProfileDrawer
                    open={drawerVisible}
                    onClose={() => {
                        setDrawerVisible(false);
                    }}
                />
            )}

            <QuickActionsModal
                open={quickActionsVisible}
                onClose={() => setQuickActionsVisible(false)}
            />

        </div>
    );
}

export default AppHeader;
