import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'api/apiClient';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import dayjs from 'dayjs';
import { Empty, List, message, notification, Spin } from 'antd';
import { NOTIFICATION_ERROR } from 'constants/messages';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { LoadingOutlined } from '@ant-design/icons';
import { CopyOutlined } from '@ant-design/icons';
import STATUS from 'constants/status';
import { hasPermission } from 'utils/utils';
import { triggerGuestPassUpdate } from 'store/slices/guestPassSlice';

const GuestPassTab: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const { selectedVisit } = useSelector((state: RootState) => state.visits);

    const [guestPassData, setGuestPassData] = useState<any>();
    const [guestPassPINDefault, setGuestPassPINDefault] = useState<boolean>(false);
    const [defaultEntryIds, setDefaultEntryIds] = useState<number[]>([]);
    const [entryIdOptions, setEntryIdOptions] = useState<{ value: number; label: string }[]>([]);
    const [isGenerateGuestPassModalOpen, setIsGenerateGuestPassModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [generateGuessPassForm] = OpForm.useForm();
    const [guestPassForm] = OpForm.useForm();

    const confirmModal = useConfirmModal();

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasGuestpassWrite = hasPermission(tokenScopeList, orgId, 'o', 'guestpass:w');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const integrationData = await getRequest(`/orgs/${orgId}/integrationMsiAlta`);
                const initialData = integrationData.data[0];
                setGuestPassPINDefault(initialData.guestPassPINDefault === 1);
                if (initialData.entryIds) {
                    setDefaultEntryIds(initialData.entryIds.map((id: string) => Number(id)));
                }

                const guestPass = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/msiAltaGuestPass`);
                if (Array.isArray(guestPass.data) && guestPass.data.length !== 0) {
                    const modifiedGuestPass = {
                        ...guestPass.data[0],
                        startUtc: guestPass.data[0].startUtc ? dayjs(guestPass.data[0].startUtc).utc(true).local() : undefined,
                        endUtc: guestPass.data[0].startUtc ? dayjs(guestPass.data[0].endUtc).utc(true).local() : undefined
                    }
                    setGuestPassData(modifiedGuestPass);
                }

                const listAllEntriesResponse = await getRequest(`/orgs/${orgId}/integrationMsiAltaEntry`);
                const defaultEntryList = listAllEntriesResponse.data
                    .map((defaultEntry: any) => ({
                        value: defaultEntry.id,
                        label: defaultEntry.name,
                    }));
                setEntryIdOptions(defaultEntryList);
            } catch (error) {
                console.log(error);
                console.error("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };

        if (orgId && selectedVisit?.id) {
            fetchData();
        }
    }, [orgId, selectedVisit?.id]);

    const handleSubmit = (async ({ values }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            // update
            if (guestPassData !== undefined) {
                const submittingValues = {
                    startUtc: dayjs(values.startUtc).utc().format(DATE_TIME_FORMAT),
                    endUtc: dayjs(values.endUtc).utc().format(DATE_TIME_FORMAT),
                    entryId: values.entryId,
                    PIN: (values.guestPassPINDefault === true)
                        ? (guestPassData.PIN === null || guestPassData.PIN === "")
                            ? Math.floor(1000 + Math.random() * 9000).toString()
                            : guestPassData.PIN
                        : ''
                };
                if (submittingValues.endUtc && submittingValues.startUtc && dayjs(submittingValues.endUtc).isBefore(dayjs(submittingValues.startUtc))) {
                    notification.error({
                        message: 'Error',
                        description: 'End date cannot be before start date.',
                        placement: 'bottomRight',
                    });
                    return;
                }

                // Edit Guest Pass
                await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/msiAltaGuestPass`, submittingValues);

                notification.success({
                    message: 'Success',
                    description: 'Guest pass updated successfully.',
                    placement: 'bottomRight',
                });
            } else { // create
                const submittingValues = {
                    startUtc: dayjs(values.startUtc).local().utc().format(DATE_TIME_FORMAT),
                    endUtc: dayjs(values.endUtc).local().utc().format(DATE_TIME_FORMAT),
                    entryId: values.entryId,
                    userId: globalUserId,
                    PIN: values.guestPassPINDefault === true ? Math.floor(1000 + Math.random() * 9000).toString() : undefined
                };
                if (submittingValues.endUtc && submittingValues.startUtc && dayjs(submittingValues.endUtc).isBefore(dayjs(submittingValues.startUtc))) {
                    notification.error({
                        message: 'Error',
                        description: 'End date cannot be before start date.',
                        placement: 'bottomRight',
                    });
                    return;
                }

                // Generate Guest Pass
                await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/msiAltaGuestPass`, submittingValues);

                notification.success({
                    message: 'Success',
                    description: 'Guest pass generated successfully.',
                    placement: 'bottomRight',
                });
            }
            const guestPass = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/msiAltaGuestPass`);
            const modifiedGuestPass = {
                ...guestPass.data[0],
                startUtc: guestPass.data[0].startUtc ? dayjs(guestPass.data[0].startUtc).utc(true).local() : undefined,
                endUtc: guestPass.data[0].startUtc ? dayjs(guestPass.data[0].endUtc).utc(true).local() : undefined
            }
            setGuestPassData(modifiedGuestPass);
            dispatch(triggerGuestPassUpdate());// inform guest pass is updated for guest pass report page
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: 'An error occurred during the submission.',
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    });

    const finalOnCancel = () => {
        if (generateGuessPassForm.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    generateGuessPassForm.resetFields();
                    setIsGenerateGuestPassModalOpen(false);
                },
            });
        } else {
            setIsGenerateGuestPassModalOpen(false);
        }
    };

    const handleRevoke = () => {
        try {
            confirmModal({
                title: 'Confirm Revoke?',
                content: 'Are you sure you want to revoke the guest pass for MSI Alta? This action cannot be undone.',
                okText: 'Revoke',
                cancelText: 'Cancel',
                onOk: async () => {
                    await deleteRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/msiAltaGuestPass`);
                    notification.success({
                        message: 'Success',
                        description: 'The guest pass for MSI Alta has been successfully revoked.',
                        placement: 'bottomRight',
                    });
                    setGuestPassData(undefined); // Reset the guest pass data
                    dispatch(triggerGuestPassUpdate()); // inform guest pass is updated for guest pass report page
                },
            });
        } catch (error) {
            console.log(`Failed to revoke guest pass.`, error);
        }
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Link copied to clipboard!');
        }).catch((error) => {
            message.error('Failed to copy the link');
            console.error('Copy failed', error);
        });
    };

    const isGuestPassActive = useCallback(() => {
        if (guestPassData?.startUtc && guestPassData?.endUtc) {
            const now = dayjs();
            return now.isAfter(guestPassData.startUtc) && now.isBefore(guestPassData.endUtc);
        }
        return false;
    }, [guestPassData]);

    const hasValidVisitor = selectedVisit?.visitors?.some(
        (visitor) => visitor.status !== STATUS.DENIED_ENTRY.id && visitor.status !== STATUS.SIGNED_OUT.id
    );

    return (
        <>
            {(loading || isSubmitting) ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined spin />} />
                </div>
            ) : (
                <>
                    {guestPassData === undefined ? (
                        <>
                            {(hasGuestpassWrite && hasValidVisitor) ? (
                                <OpButton
                                    type="primary" ghost
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        setIsGenerateGuestPassModalOpen(true);
                                    }}
                                >
                                    Generate Guest Pass
                                </OpButton>
                            ) : (
                                <Empty description="No Guest Pass" />
                            )}
                        </>
                    ) : (
                        <>
                            <OpForm
                                form={guestPassForm}
                                onSubmit={() => { }}
                                initialValues={guestPassData}
                                hasError={false}
                                defaultButtons={false}
                                isReadOnly={true}
                            >
                                {!isGuestPassActive() && (
                                    <div style={{
                                        backgroundColor: 'grey',
                                        color: 'white',
                                        padding: '10px 0',
                                        textAlign: 'center',
                                        borderRadius: '16px',
                                        fontWeight: 'bold',
                                        marginBottom: '16px'
                                    }}>
                                        Invalid or Expired
                                    </div>
                                )}

                                {isGuestPassActive() && (
                                    <div style={{
                                        backgroundColor: '#709A7A',
                                        color: 'white',
                                        padding: '10px 0',
                                        textAlign: 'center',
                                        borderRadius: '16px',
                                        fontWeight: 'bold',
                                        marginBottom: '16px'
                                    }}>
                                        Valid
                                    </div>
                                )}

                                <OpRow gutter={24}>
                                    <OpCol span={12}>
                                        <OpForm.DatePicker
                                            format={DATE_TIME_AM_PM_FORMAT}
                                            showTime
                                            minuteStep={5}
                                            label="Valid From"
                                            name="startUtc"
                                            rules={[{ required: true, message: 'Please enter start date.' }]}
                                        />
                                    </OpCol>
                                    <OpCol span={12}>
                                        <OpForm.DatePicker
                                            format={DATE_TIME_AM_PM_FORMAT}
                                            showTime
                                            minuteStep={5}
                                            label="Valid To"
                                            name="endUtc"
                                            rules={[{ required: true, message: 'Please enter end date.' }]}
                                        />
                                    </OpCol>
                                </OpRow>
                                <List
                                    header={<div>GUEST PASS URL</div>}
                                    dataSource={[{ label: 'GUEST PASS URL' }]}
                                    bordered
                                    renderItem={item => (
                                        <List.Item>
                                            <div style={{ width: '100%' }}>
                                                {/* Display Guest Pass URL */}
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <button
                                                        type="button"
                                                        onClick={(e) => e.preventDefault()}
                                                        style={{ color: '#1890ff', background: 'none', border: 'none', padding: 0, cursor: 'pointer', textDecoration: 'underline' }}
                                                    >
                                                        {`https://control.invisit.us/guestpass/${guestPassData.urlToken}`}
                                                    </button>
                                                    <div
                                                        onClick={() => copyToClipboard(
                                                            guestPassData.PIN
                                                                ? `https://control.invisit.us/guestpass/${guestPassData.urlToken}\nPIN: ${guestPassData.PIN}`
                                                                : `https://control.invisit.us/guestpass/${guestPassData.urlToken}`
                                                        )}
                                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                    >
                                                        <CopyOutlined style={{ marginRight: '4px' }} />
                                                        Copy
                                                    </div>
                                                </div>

                                                {guestPassData.PIN && (
                                                    <div style={{ marginTop: '8px' }}>
                                                        PIN: {guestPassData.PIN}
                                                    </div>
                                                )}
                                            </div>
                                        </List.Item>
                                    )}
                                />
                                {(hasGuestpassWrite) && (
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                                        <OpButton
                                            type="primary"
                                            onClick={() => {
                                                setIsGenerateGuestPassModalOpen(true);
                                            }}
                                            style={{
                                                width: '160px',
                                                padding: '4px 0',
                                                marginRight: '16px'
                                            }}
                                        >
                                            Edit Guest Pass
                                        </OpButton>
                                        <OpButton
                                            type="primary" danger
                                            onClick={handleRevoke}
                                            style={{
                                                width: '160px',
                                                padding: '4px 0',
                                            }}
                                        >
                                            Revoke Guest Pass
                                        </OpButton>
                                    </div>
                                )}
                            </OpForm>
                        </>
                    )}

                    {isGenerateGuestPassModalOpen && (
                        <OpModal
                            title={guestPassData ? 'Edit Guest Pass' : 'Generate Guest Pass'}
                            open={isGenerateGuestPassModalOpen}
                            onClose={finalOnCancel}
                            onOk={() => {
                                generateGuessPassForm
                                    .validateFields()
                                    .then(() => {
                                        generateGuessPassForm.submit();
                                        setIsGenerateGuestPassModalOpen(false);
                                    })
                                    .catch((errorInfo) => {
                                        notification.error({
                                            message: 'Validation Error',
                                            description: 'Please fill in all required fields.',
                                            placement: 'bottomRight',
                                        });
                                    });
                            }}
                            onCancel={finalOnCancel}
                            centered
                        >
                            <OpForm
                                form={generateGuessPassForm}
                                onSubmit={handleSubmit}
                                hasError={false}
                                defaultButtons={false}
                                initialValues={guestPassData ? {
                                    startUtc: guestPassData.startUtc,
                                    endUtc: guestPassData.endUtc,
                                    entryId: guestPassData.msiAltaEntryId.map((entry: any) => entry.id),
                                    guestPassPINDefault: (guestPassData.PIN === null || guestPassData.PIN === "") ? false : true
                                } : {
                                    startUtc: dayjs(),
                                    endUtc: dayjs().add(1, 'hour'),
                                    entryId: defaultEntryIds,
                                    guestPassPINDefault: guestPassPINDefault
                                }}
                            >
                                <OpForm.DatePicker
                                    format={DATE_TIME_AM_PM_FORMAT}
                                    showTime
                                    minuteStep={5}
                                    label="Valid From"
                                    name="startUtc"
                                    rules={[{ required: true, message: 'Please enter start date.' }]}
                                    onChange={!guestPassData ? (value) => {
                                        if (value) {
                                            const endUtcValue = dayjs(value).add(1, 'hour');
                                            generateGuessPassForm.setFieldsValue({
                                                endUtc: endUtcValue,
                                            });
                                        }
                                    } : undefined}
                                />
                                <OpForm.DatePicker
                                    format={DATE_TIME_AM_PM_FORMAT}
                                    showTime
                                    minuteStep={5}
                                    label="Valid To"
                                    name="endUtc"
                                    rules={[{ required: true, message: 'Please enter end date.' }]}
                                />
                                <OpForm.Select
                                    name={"entryId"}
                                    label={"Entries"}
                                    placeholder={'Select entries'}
                                    mode="multiple"
                                    options={entryIdOptions}
                                    rules={[{ required: true, message: 'Please select entries.' }]}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <OpForm.Switch
                                        name={"guestPassPINDefault"}
                                    />
                                    <span style={{ marginLeft: 8 }}>Require PIN to Access</span>
                                </div>
                            </OpForm>
                        </OpModal>
                    )}
                </>
            )}
        </>
    )
};

export default GuestPassTab;
