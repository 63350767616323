import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import UsersTable from './UsersTable';
import UsersDrawer from './UsersDrawer';
import { USERS_TOOLTIP } from 'constants/tooltip';
import { getRequest } from 'api/apiClient';
import { RootState } from 'store/store';
import { User } from 'types/userTypes';
import { Role } from 'types/roleTypes';

const Users: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch users and roles
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const users = await getRequest(`/orgs/${orgId}/users`);
                const filteredUsers = users.data.filter((user: User) => user.status === 1);
                setUsers(filteredUsers);

                const roles = await getRequest(`/orgs/${orgId}/roles`);
                const filteredRoles = roles.data.filter((role: Role) => role.status === 1);
                setRoles(filteredRoles);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    const handleEditClick = (user: User) => {
        setSelectedUser(user);
        setDrawerVisible(true);
    };

    const handleAddClick = () => {
        setSelectedUser(null);
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setSelectedUser(null);
    };

    return (
        <OpPage title="Users" tooltip={USERS_TOOLTIP}>
            <UsersTable
                users={users}
                roles={roles}
                loading={loading}
                setUsers={setUsers}
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
            />
            <UsersDrawer
                open={drawerVisible}
                selectedUser={selectedUser}
                roles={roles}
                onClose={handleDrawerClose}
                setUsers={setUsers}
            />
        </OpPage>
    );
};

export default Users;
