import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { RootState } from "store/store";
import { hasPermission } from "utils/utils";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import { DRAWER_WIDTH } from "constants/ui";
import { Agreement, AgreementContent } from "types/agreementTypes";
import { OpFormDrawer } from "components/customAntd/DLS/OpFormDrawer/OpFormDrawer";
import AgreementForm from "./tabs/AgreementForm";
import ContentForm from "./tabs/ContentForm";
import { getRequest, patchRequest, postRequest } from "api/apiClient";
import { DATE_TIME_AM_PM_FORMAT } from "constants/dates";
import dayjs from 'dayjs';
import { notification } from "antd";
import { AGREEMENT_EDITED_ID } from "constants/userActivities";
import { AGREEMENT_CREATE_SUCCESS, AGREEMENT_SAVE_ERROR, AGREEMENT_UPDATE_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "constants/messages";

interface AgreementsDrawerProps {
    open: boolean;
    selectedAgreement: Agreement | null;
    onClose: () => void;
    setAgreements: (agreements: Agreement[]) => void;
}

const AgreementsDrawer: React.FC<AgreementsDrawerProps> = ({ open, selectedAgreement, onClose, setAgreements }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasAgreementsRead = hasPermission(tokenScopeList, orgId, 'o', 'agreements:r');
    const hasAgreementsWrite = hasPermission(tokenScopeList, orgId, 'o', 'agreements:w');

    const [activeKey, setActiveKey] = useState<string>('agreement');
    const [selectedAgreementContent, setSelectedAgreementContent] = useState<AgreementContent | null>(null);
    const [type, setType] = useState<string>(selectedAgreement?.type ? selectedAgreement.type : 'text');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    useEffect(() => {
        if (open) {
            setActiveKey('agreement');
        }
    }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (selectedAgreement && selectedAgreement.id) {
                    const agreementContent = await getRequest(`/orgs/${orgId}/termsAgreements/${selectedAgreement.id}/documentContent`);
                    setSelectedAgreementContent(agreementContent.data[0]);
                }
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId, selectedAgreement?.id, selectedAgreement]);

    const initialValues = (selectedAgreement && selectedAgreementContent) ? {
        name: selectedAgreement.name,
        description: selectedAgreement.description,
        type: selectedAgreement.type,
        updatedAt: dayjs(selectedAgreement.updatedAt).utc(true).local().format(DATE_TIME_AM_PM_FORMAT),
        forInvitation: selectedAgreement.forInvitation,
        forSignIn: selectedAgreement.forSignIn,
        forSignOut: selectedAgreement.forSignOut,
        forRegistration: selectedAgreement.forRegistration,
        content: selectedAgreementContent.content,
    } : {
        forInvitation: true,
        forSignIn: true,
        type: type
    };

    const tabItems = [
        {
            key: 'agreement',
            label: 'Agreement',
            children: <AgreementForm selectedAgreement={selectedAgreement} type={type} setType={setType} />,
        },
        ...((!selectedAgreement || selectedAgreement.type === 'text') ? [{
            key: 'content',
            label: 'Content',
            children: <ContentForm />,
        }] : [])
    ];

    const handleSubmit = useCallback(async ({ values, touchedValues, initialValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            // Transform null values to empty strings and boolean values to 1/0
            const transformedValues = Object.keys(touchedValues).reduce((acc, key) => {
                let value = touchedValues[key];

                // Convert boolean values to 1/0
                if (typeof value === 'boolean') {
                    value = value ? 1 : 0;
                } else if (value === null) {
                    value = ''; // Handle null values
                }

                acc[key] = value;
                return acc;
            }, {} as Record<string, any>);

            // Exclude 'content' from transformedValues
            delete transformedValues.content;

            if (selectedAgreement) {
                // Update the existing agreement
                if (transformedValues && Object.keys(transformedValues).length > 0 && selectedAgreement.id) {
                    await patchRequest(`/orgs/${orgId}/termsAgreements/${selectedAgreement.id}`, transformedValues);
                }
                await postRequest(`/orgs/${orgId}/userActivity`, {
                    userId: globalUserId,
                    activityId: AGREEMENT_EDITED_ID,
                    details: selectedAgreement.name
                });

                if (values.content !== undefined && values.content !== initialValues?.content && selectedAgreement.id) {
                    await patchRequest(`/orgs/${orgId}/termsAgreements/${selectedAgreement.id}/documentContent`, {
                        content: values.content
                    });
                }

                notification.success({
                    message: NOTIFICATION_SUCCESS,
                    description: AGREEMENT_UPDATE_SUCCESS,
                    placement: 'bottomRight',
                });
            } else {
                // Set the 'type' property to 'text' (this will be modified later)
                transformedValues.type = 'text';

                // Create a new agreement
                const response = await postRequest(`/orgs/${orgId}/termsAgreements`, transformedValues);
                await postRequest(`/orgs/${orgId}/userActivity`, {
                    userId: globalUserId,
                    activityId: AGREEMENT_EDITED_ID,
                    details: transformedValues.name
                });

                if (response.data[0].id) {
                    await patchRequest(`/orgs/${orgId}/termsAgreements/${response.data[0].id}/documentContent`, {
                        content: values.content
                    });
                }

                notification.success({
                    message: NOTIFICATION_SUCCESS,
                    description: AGREEMENT_CREATE_SUCCESS,
                    placement: 'bottomRight',
                });
            }
            // Re-fetch the updated list of agreements
            const agreements = await getRequest(`/orgs/${orgId}/termsAgreements`);
            const filteredAgreements = agreements.data.filter((agreement: Agreement) => agreement.status === 1);
            setAgreements(filteredAgreements);

            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: AGREEMENT_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [selectedAgreement, orgId, form, globalUserId, onClose, setAgreements]);

    return (
        <OpFormDrawer
            form={form}
            title={selectedAgreement ? selectedAgreement.name : 'Add New Agreement'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormLoading={loading || isSubmitting}
            isFormReadOnly={!hasAgreementsWrite && hasAgreementsRead}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                    isReadOnly={!hasAgreementsWrite && hasAgreementsRead}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
};

export default AgreementsDrawer;
