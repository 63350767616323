import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { DRAWER_WIDTH } from 'constants/ui';
import { DATE_FORMAT } from 'constants/dates';
import {
    NOTIFICATION_ERROR,
    NOTIFICATION_SUCCESS,
    USER_CREATE_ERROR_ALREADY_EXISTS,
    USER_CREATE_SUCCESS,
    USER_DELETE_ERROR_DATE_ORDER,
    USER_DELETE_ERROR_SUPER_ADMIN,
    USER_SAVE_ERROR,
    USER_UPDATE_SUCCESS,
} from 'constants/messages';
import { USER_CREATED_ID, USER_EDITED_ID } from 'constants/userActivities';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { RootState } from 'store/store';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { formatFullName, hasPermission, transformNullToEmptyString } from 'utils/utils';
import { User } from 'types/userTypes';
import { Role } from 'types/roleTypes';
import UserForm from './tabs/UserForm';
import OptionsForm from './tabs/OptionsForm';

interface UsersDrawerProps {
    open: boolean;
    onClose: () => void;
    selectedUser: User | null;
    setUsers: (users: User[]) => void;
    roles: Role[];
}

const UsersDrawer: React.FC<UsersDrawerProps> = ({ open, onClose, selectedUser, setUsers, roles }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasUserRead = hasPermission(tokenScopeList, orgId, 'o', 'user:r');
    const hasUserWrite = hasPermission(tokenScopeList, orgId, 'o', 'user:w');

    const [activeKey, setActiveKey] = useState<string>('user');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [selectedUserRoles, setSelectedUserRoles] = useState<Role[]>([]);
    const [isFetchingSelectedUserRoles, setIsFetchingSelectedUserRoles] = useState<boolean>(false);
    const [superAdminUsers, setSuperAdminUsers] = useState<User[]>([]);

    const [form] = OpForm.useForm();

    // Reset the active tab to 'user' when the drawer opens
    useEffect(() => {
        if (open) {
            setActiveKey('user');
        }
    }, [open]);

    const initialValues = selectedUser ? {
        ...selectedUser,
        startDate: selectedUser.startDate && selectedUser.startDate !== '0000-00-00 00:00:00'
            ? dayjs(selectedUser.startDate).format(DATE_FORMAT)
            : undefined,
        endDate: selectedUser.endDate && selectedUser.endDate !== '0000-00-00 00:00:00'
            ? dayjs(selectedUser.endDate).format(DATE_FORMAT)
            : undefined,
        roleId: selectedUserRoles.map(role => role.id)
    } : {};

    const tabItems = [
        {
            key: 'user',
            label: 'User',
            children: <UserForm selectedUser={selectedUser} roles={roles} />,
        },
        {
            key: 'options',
            label: 'Options',
            children: <OptionsForm />,
        },
    ];

    // Fetch selected user roles
    useEffect(() => {
        if (selectedUser) {
            const fetchSelectedUserRoles = async () => {
                setIsFetchingSelectedUserRoles(true);
                try {
                    const selectedUserRoles = await getRequest(`/orgs/${orgId}/users/${selectedUser.id}/roles`);
                    setSelectedUserRoles(selectedUserRoles.data);
                } catch (error) {
                    console.log("Failed to fetch selected user roles.");
                } finally {
                    setIsFetchingSelectedUserRoles(false);
                }
            };
            fetchSelectedUserRoles();

            const superAdminRole = roles.find(role => role.admin);
            if (superAdminRole) {
                const fetchSuperAdminUsers = async () => {
                    try {
                        const superAdminUsers = await getRequest(`/orgs/${orgId}/roles/${superAdminRole.id}/users`);
                        const filteredSuperAdminUsers = superAdminUsers.data.filter((user: User) => user.status === 1);
                        setSuperAdminUsers(filteredSuperAdminUsers);
                    } catch (error) {
                        console.log("Failed to fetch super admin users.");
                    }
                };
                fetchSuperAdminUsers();
            }
        }
    }, [orgId, selectedUser, roles]);

    const handleSubmit = useCallback(async ({ values, touchedValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);

        try {
            if (touchedValues && Object.keys(touchedValues).length > 0) {
                const transformedValues = transformNullToEmptyString(touchedValues);

                // Rename identity.mobilePhone to identity.mobile if it exists
                if (transformedValues.identity?.mobilePhone) {
                    transformedValues.identity.mobile = transformedValues.identity.mobilePhone;
                    delete transformedValues.identity.mobilePhone; // Remove the original key
                }

                // Transform startDate and endDate to UTC with appropriate time (00:00:00 and 23:59:59)
                if (transformedValues.startDate) {
                    transformedValues.startDate = dayjs.utc(transformedValues.startDate)
                        .startOf('day')
                        .format(); // Set time to 00:00:00 UTC
                }

                if (transformedValues.endDate) {
                    transformedValues.endDate = dayjs.utc(transformedValues.endDate)
                        .endOf('day')
                        .format(); // Set time to 23:59:59 UTC
                }

                // Validation for date order
                if (values.startDate && values.endDate
                    && dayjs(values.endDate).isBefore(dayjs(values.startDate))) {
                    notification.error({
                        message: NOTIFICATION_ERROR,
                        description: USER_DELETE_ERROR_DATE_ORDER,
                        placement: 'bottomRight',
                    });
                    return;
                }

                // Check if "Super Admin" role is being removed
                const superAdminRole = roles.find(role => role.admin);
                if (superAdminRole && transformedValues.roleId) {
                    const currentUserRoleIDs = selectedUserRoles.map(role => role.id);
                    const isRemovingSuperAdmin = currentUserRoleIDs.includes(superAdminRole.id) && !transformedValues.roleId.includes(superAdminRole.id);

                    if (isRemovingSuperAdmin) {
                        // Check if the only remaining "Super Admin" user is being deleted
                        if (superAdminUsers.length === 1 && superAdminUsers[0].id === selectedUser?.id) {
                            notification.error({
                                message: NOTIFICATION_ERROR,
                                description: USER_DELETE_ERROR_SUPER_ADMIN(superAdminRole.name!),
                                placement: 'bottomRight',
                            });
                            return;
                        }
                    }
                }

                if (selectedUser) {
                    await patchRequest(`/orgs/${orgId}/users/${selectedUser.id}`, transformedValues);
                    await postRequest(`/orgs/${orgId}/userActivity`, {
                        userId: globalUserId,
                        activityId: USER_EDITED_ID,
                        details: formatFullName(values.identity.firstName, values.identity.middleName, values.identity.lastName)
                    });
                    notification.success({
                        message: NOTIFICATION_SUCCESS,
                        description: USER_UPDATE_SUCCESS,
                        placement: 'bottomRight',
                    });
                } else {
                    try {
                        const response = await postRequest(`/orgs/${orgId}/users`, transformedValues);
                        await postRequest(`/auth/resetPassword`, {
                            email: response.data[0].identity.email,
                        });
                        await postRequest(`/orgs/${orgId}/userActivity`, {
                            userId: globalUserId,
                            activityId: USER_CREATED_ID,
                            details: formatFullName(values.identity.firstName, values.identity.middleName, values.identity.lastName)
                        });
                        notification.success({
                            message: NOTIFICATION_SUCCESS,
                            description: USER_CREATE_SUCCESS,
                            placement: 'bottomRight',
                        });
                    } catch (error) {
                        notification.error({
                            message: NOTIFICATION_ERROR,
                            description: USER_CREATE_ERROR_ALREADY_EXISTS,
                            placement: 'bottomRight',
                        });
                    }
                }
                // Re-fetch the updated list of users
                const updatedUsers = await getRequest(`/orgs/${orgId}/users`);
                const filteredUpdatedUsers = updatedUsers.data.filter((user: User) => user.status === 1);
                setUsers(filteredUpdatedUsers);
            }
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: USER_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [orgId, form, onClose, globalUserId, roles, selectedUser, selectedUserRoles, setUsers, superAdminUsers]);

    return (
        <OpFormDrawer
            form={form}
            title={selectedUser ? formatFullName(selectedUser.identity.firstName, selectedUser.identity.middleName, selectedUser.identity.lastName) : 'Add New User'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormLoading={isFetchingSelectedUserRoles || isSubmitting}
            isFormReadOnly={!hasUserWrite && hasUserRead}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                    isReadOnly={!hasUserWrite && hasUserRead}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
};

export default UsersDrawer;
