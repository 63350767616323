import React from 'react';
import { useSelector } from 'react-redux';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { RootState } from 'store/store';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName, hasPermission } from 'utils/utils';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { RedFlag } from 'types/redFlagTypes';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/dates';

interface RedFlagsTableProps {
    redFlags: RedFlag[];
    loading: boolean;
    handleEditClick: (redFlag: RedFlag) => void;
    handleAddClick: () => void;
}

const RedFlagsTable: React.FC<RedFlagsTableProps> = ({ redFlags, loading, handleEditClick, handleAddClick }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    const transformedRedFlags = redFlags.map((item: RedFlag) => ({
        ...item,
        dateOfBirth: dayjs(item.dateOfBirth).isValid() ? dayjs(item.dateOfBirth).format(DATE_FORMAT) : null,
        fullName: formatFullName(item.firstName, item.middleName, item.lastName),
    }));

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'levelName',
            label: "Red Flag Type",
            width: 300,
            render: (text: string) => {
                if (text === 'Blocklist') {
                    return <span><StopOutlined style={{ color: 'rgba(242,103,87,1.0)' }} /> {text}</span>;
                } else if (text === 'Watchlist') {
                    return <span><EyeOutlined style={{ color: '#FFD54F' }} /> {text}</span>;
                }
                return text;
            },
            filter: {
                type: 'multiSelect',
                options: [
                    { label: 'Blocklist', value: 'Blocklist' },
                    { label: 'Watchlist', value: 'Watchlist' }
                ],
            },
            sorter: (a, b) => (a.levelName || '').localeCompare(b.levelName || ''),
        },
        {
            dataIndex: 'fullName',
            label: "Full Name",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.fullName || '').localeCompare(b.fullName || ''),
        },
        {
            dataIndex: 'dateOfBirth',
            label: "Date of Birth",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.dateOfBirth || '').localeCompare(b.dateOfBirth || ''),
        },
        {
            dataIndex: 'identification',
            label: "Identification",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.identification || '').localeCompare(b.identification || ''),
        },
        {
            dataIndex: 'address',
            label: "Address",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.address || '').localeCompare(b.address || ''),
        },
        {
            dataIndex: 'city',
            label: "City",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.city || '').localeCompare(b.city || ''),
        },
        {
            dataIndex: 'postalCode',
            label: "Postal Code",
            hidden: true,
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.postalCode || '').localeCompare(b.postalCode || ''),
        }
    ];

    const opTableProps: IOpTableProps = {
        dataSource: transformedRedFlags,
        columns: columns,
        rowActions: {
            onEditClick: (redFlag: RedFlag) => handleEditClick(redFlag),
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: loading,
        writeAccess: hasRedflagWrite,
        allowExport: true,
        allowShowHideColumns: true,
    };

    return <OpTable {...opTableProps} />;
};

export default RedFlagsTable;
