import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { DRAWER_WIDTH } from 'constants/ui';
import {
    FIELD_CREATE_SUCCESS,
    FIELD_SAVE_ERROR,
    FIELD_UPDATE_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATION_SUCCESS,
} from 'constants/messages';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { RootState } from 'store/store';
import { FormField } from 'types/formFieldTypes';
import { hasPermission, transformNullToEmptyString } from 'utils/utils';
import FieldForm from './tabs/FieldForm';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { FIELD_CREATED_ID, FIELD_EDITED_ID } from 'constants/userActivities';

interface FormFieldsDrawerProps {
    open: boolean;
    onClose: () => void;
    selectedFormField: FormField | null;
    setFormFields: (formFields: FormField[]) => void;
}

const FormFieldsDrawer: React.FC<FormFieldsDrawerProps> = ({ open, onClose, selectedFormField, setFormFields }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasFormFieldsRead = hasPermission(tokenScopeList, orgId, 'o', 'fields:r');
    const hasFormFieldsWrite = hasPermission(tokenScopeList, orgId, 'o', 'fields:w');

    const [activeKey, setActiveKey] = useState<string>('field');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    // Reset the active tab to 'formField' when the drawer opens
    useEffect(() => {
        if (open) {
            setActiveKey('field');
        }
    }, [open]);

    const initialValues = selectedFormField ? selectedFormField : {};

    const tabItems = [
        {
            key: 'field',
            label: 'Field',
            children: <FieldForm />,
        },
    ];

    const handleSubmit = useCallback(async ({ values, touchedValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            if (touchedValues && Object.keys(touchedValues).length > 0) {
                if (touchedValues.values) {
                    touchedValues.values = values.values
                        .filter((item: { value: string }) => item.value !== undefined && item.value.trim() !== '')
                        .map((item: { value: string }) => item.value)
                        .join('|');
                }
                const transformedValues = transformNullToEmptyString(touchedValues);
                if (selectedFormField) {
                    await patchRequest(`/orgs/${orgId}/formField/${selectedFormField.id}`, transformedValues);
                    await postRequest(`/orgs/${orgId}/userActivity`, {
                        userId: globalUserId,
                        activityId: FIELD_EDITED_ID,
                        details: values.name
                    });
                    notification.success({
                        message: NOTIFICATION_SUCCESS,
                        description: FIELD_UPDATE_SUCCESS,
                        placement: 'bottomRight',
                    });
                } else {
                    await postRequest(`/orgs/${orgId}/formField`, transformedValues);
                    await postRequest(`/orgs/${orgId}/userActivity`, {
                        userId: globalUserId,
                        activityId: FIELD_CREATED_ID,
                        details: values.name
                    });
                    notification.success({
                        message: NOTIFICATION_SUCCESS,
                        description: FIELD_CREATE_SUCCESS,
                        placement: 'bottomRight',
                    });
                }
                // Re-fetch the updated list of fields
                const updatedFields = await getRequest(`/orgs/${orgId}/formField`);
                const filteredUpdatedFormFields = updatedFields.data.filter((field: FormField) => field.status === 1);
                setFormFields(filteredUpdatedFormFields);
            }
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: FIELD_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [form, onClose, globalUserId, orgId, selectedFormField, setFormFields]);

    return (
        <OpFormDrawer
            form={form}
            title={selectedFormField ? selectedFormField.name : 'Add New Field'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormLoading={isSubmitting}
            isFormReadOnly={!hasFormFieldsWrite && hasFormFieldsRead}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                    isReadOnly={!hasFormFieldsWrite && hasFormFieldsRead}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
};

export default FormFieldsDrawer;
