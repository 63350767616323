import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button, List, Input, Form, notification } from 'antd';
import { RootState } from "store/store";
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { hasPermission } from 'utils/utils';
import { RedFlag, RedFlagLog } from 'types/redFlagTypes';
import { getRequest, postRequest } from 'api/apiClient';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, RED_FLAG_LOG_ADD_SUCCESS, RED_FLAG_SAVE_ERROR } from 'constants/messages';

const { TextArea } = Input;

interface LogFormProps {
    selectedRedFlag: RedFlag;
    redFlagLogs: RedFlagLog[];
    setRedFlagLogs: (redFlagLogs: RedFlagLog[]) => void;
}

const LogForm: React.FC<LogFormProps> = ({ selectedRedFlag, redFlagLogs, setRedFlagLogs }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    const [log, setLog] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const handleAddLog = async () => {
        if (!log.trim()) {
            setError('Please enter a log');
            return;
        }

        try {
            const newLog = { log, timestamp: dayjs().format(DATE_TIME_FORMAT) };
            await postRequest(`/orgs/${orgId}/redflag/${selectedRedFlag.id}/redFlagLog`, {
                logUserId: Number(globalUserId),
                log: newLog.log
            });

            const redFlagLogs = await getRequest(`/orgs/${orgId}/redflag/${selectedRedFlag.id}/redFlagLog`);
            setRedFlagLogs(redFlagLogs.data);

            setLog(''); // Clear the input field after submission
            setError(null); // Clear the error after successful submission

            notification.success({
                message: NOTIFICATION_SUCCESS,
                description: RED_FLAG_LOG_ADD_SUCCESS,
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: RED_FLAG_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        }
    }

    return (
        <>
            {hasRedflagWrite && (
                <div style={{ marginBottom: '16px' }}>
                    <Form.Item
                        validateStatus={error ? 'error' : ''}
                        help={error}
                    >
                        <TextArea
                            value={log}
                            onChange={(e) => {
                                setLog(e.target.value);
                                if (error) setError(null); // Clear error on input
                            }}
                            rows={4}
                            placeholder="Add new log"
                        />
                    </Form.Item>
                    <Button
                        type="primary"
                        onClick={handleAddLog}
                        style={{ marginTop: '8px' }}
                    >
                        Save New Log
                    </Button>
                </div>
            )}
            <List
                dataSource={[...redFlagLogs].reverse()} // Reverse the logs array to display new logs at the top
                renderItem={(log) => (
                    <List.Item
                        key={log.timestamp}
                        style={{
                            border: '1px solid #d9d9d9',
                            borderRadius: '12px',
                            padding: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        <List.Item.Meta
                            description={dayjs(log.timestamp).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)}
                        />
                        {log.log}
                    </List.Item>
                )}
            />
        </>
    );
};

export default LogForm;
