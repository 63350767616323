import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchVisitsToday } from 'store/slices/visitsSlice';

interface SignInMethodModalProps {
    open: boolean;
    onClose: () => void;
    openSignInModal: () => void;
    openScanIDModal: () => void;
    openScanQRCodeModal: () => void;
}

const SignInMethodModal: React.FC<SignInMethodModalProps> = ({ open, onClose, openSignInModal, openScanIDModal, openScanQRCodeModal }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const { globalSignInWorkflowConfig } = useSelector((state: RootState) => state.visitorWorkflows);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchVisitsToday({ orgId }));
            } catch (error) {
                console.log("Failed to fetch data.");
            }
        };
        fetchData();
    }, [dispatch, orgId]);

    return (
        <Modal
            title="Choose a Method to Sign In"
            open={open}
            onOk={onClose}
            onCancel={onClose}
            centered
            footer={null}
        >
            <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '16px' }}>
                <OpButton
                    type="default"
                    style={{ width: '100%', backgroundColor: 'rgba(19,151,213,0.1)' }}
                    onClick={() => {
                        openSignInModal();
                        onClose();
                    }}
                >
                    SELECT VISITOR
                </OpButton>
                {((globalSignInWorkflowConfig?.scan === 1) && (globalSignInWorkflowConfig?.scanScanner === 1)) && (

                    <OpButton
                        type="default"
                        style={{ width: '100%', backgroundColor: 'rgba(19,151,213,0.1)' }}
                        onClick={() => {
                            openScanIDModal();
                            onClose();
                        }}
                    >
                        SCAN ID
                    </OpButton>
                )}
                {(invitationConfig?.allowPreRegistration === 1) && (
                    <OpButton
                        type="default"
                        style={{ width: '100%', backgroundColor: 'rgba(19,151,213,0.1)' }}
                        onClick={() => {
                            openScanQRCodeModal();
                            onClose();
                        }}
                    >
                        SCAN QR CODE
                    </OpButton>
                )}
            </OpSpace>
        </Modal>
    );
};

export default SignInMethodModal;
