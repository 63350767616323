import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import DateRangeFilter from 'components/customAntd/DateRangeFilter';

// Import constants
import { DATE_FORMAT, DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import { UserActivity } from 'types/userActivityTypes';
import { formatFullName } from 'utils/utils';

dayjs.extend(isBetween);

interface ActivityLogTableData {
    id: number;
    user: string | null;
    activityType: string | null;
    updatedAt: string;
    activityDetails: string | null;
}

interface ActivityLogTableProps {
    userActivities: UserActivity[];
    loading: boolean;
    handleEditClick: (userActivity: UserActivity) => void;
}

const ActivityLogTable: React.FC<ActivityLogTableProps> = ({ userActivities, loading, handleEditClick }) => {
    const [filteredActivities, setFilteredActivities] = useState<UserActivity[]>([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('week').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().endOf('week').endOf('day'));

    // Filter activities based on date range
    useEffect(() => {
        const filteredActivities = userActivities.filter((activity) => {
            return dayjs(activity.updatedAt).utc(true).local().isBetween(startDate, endDate, null, '[]');
        });
        setFilteredActivities(filteredActivities);

    }, [userActivities, startDate, endDate]);

    const handleDateRangeFilter = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setStartDate(start);
        setEndDate(end);
    };

    // Format activities for table display
    const formatActivitiesForTable = (activities: UserActivity[]): ActivityLogTableData[] => {
        return activities.map((activity) => {
            const fullName = formatFullName(activity.user.firstName, null, activity.user.lastName);
            return {
                id: activity.id,
                user: fullName && fullName.trim() !== '' ? fullName : activity.user.email,
                activityType: activity.activityType,
                updatedAt: dayjs(activity.updatedAt).utc(true).local().format(DATE_TIME_AM_PM_FORMAT),
                activityDetails: activity.activityDetails
            };
        });
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'user',
            label: 'User',
            filter: { type: 'input' },
            sorter: (a, b) => a.user.length - b.user.length
        },
        {
            dataIndex: 'activityType',
            label: 'Activity Type',
            filter: { type: 'input' },
            sorter: (a, b) => a.activityType.length - b.activityType.length
        },
        {
            dataIndex: 'updatedAt',
            label: 'Date & Time',
            sorter: (a, b) => dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix()
        },
        {
            dataIndex: 'activityDetails',
            label: 'Details',
            filter: { type: 'input' },
            sorter: (a, b) => a.activityDetails.length - b.activityDetails.length,
            hidden: true
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formatActivitiesForTable(filteredActivities),
        label: `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: handleEditClick,
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowExport: true,
        allowShowHideColumns: true,
        loading: loading,
        rowKey: 'id',
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeFilter
                onDateRangeFilter={handleDateRangeFilter}
                initialStartDate={startDate}
                initialEndDate={endDate}
            />
            <OpTable {...opTableProps} />
        </OpSpace>
    );
}

export default ActivityLogTable;
