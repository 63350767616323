import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateVisitorWorkflow, updateVisitorWorkflowConfig } from 'store/slices/visitorWorkflowsSlice';
import { List } from 'antd';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import locationIcon from '../../location.svg';
import { hasPermission } from 'utils/utils';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';

interface WorkflowContentProps {
    form: any;
}

const visitorInfoData = [
    { name: 'additionalVisitors', label: 'Additional Visitors', valuePropName: 'checked' },
    { name: 'visitorPhoto', label: 'Take Visitor Photo', valuePropName: 'checked' },
    { name: 'visitorPhotoEach', label: 'Photo Per Visitor', valuePropName: 'checked', dependent: true },
];

const notificationData = [
    { name: 'notifyHost', label: 'Notify Host', valuePropName: 'checked' },
];

const WorkflowContent: React.FC<WorkflowContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisitorWorkflow, selectedVisitorWorkflowLocations: visitorWorkflowLocations, selectedSignInWorkflowConfig: visitorWorkflowConfig } = useSelector((state: RootState) => state.visitorWorkflows);
    const [isVisitorPhotoEachDisabled, setVisitorPhotoEachDisabled] = useState(true);
    const [isFeatureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);
    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    useEffect(() => {
        const initialVisitorPhotoState = visitorWorkflowConfig?.visitorPhoto;
        setVisitorPhotoEachDisabled(!initialVisitorPhotoState);
        if (!initialVisitorPhotoState) {
            form.setFieldsValue({ visitorPhotoEach: false });
        }
    }, [visitorWorkflowConfig, form]);

    const initialValues = useMemo(() => {
        if (selectedVisitorWorkflow) {
            return {
                name: selectedVisitorWorkflow.name,
                workflowType: "Kiosk Sign In Workflow",
                additionalVisitors: visitorWorkflowConfig?.additionalVisitors,
                visitorPhoto: visitorWorkflowConfig?.visitorPhoto,
                visitorPhotoEach: visitorWorkflowConfig?.visitorPhotoEach,
                notifyHost: visitorWorkflowConfig?.notifyHost,
            };
        }
        return {};
    }, [selectedVisitorWorkflow, visitorWorkflowConfig]);

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        const visitorWorkflowConfigParams = {
            additionalVisitors: values.additionalVisitors ? 1 : 0,
            visitorPhoto: values.visitorPhoto ? 1 : 0,
            visitorPhotoEach: values.visitorPhotoEach ? 1 : 0,
            notifyHost: values.notifyHost ? 1 : 0,
        };

        if (selectedVisitorWorkflow) {
            dispatch(updateVisitorWorkflow({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflow: { name: touchedValues.name } }));
            dispatch(updateVisitorWorkflowConfig({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflowConfigParams }));
        }
    }, [dispatch, orgId, selectedVisitorWorkflow]);

    const handleVisitorPhotoChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential" ||
            subscriptions?.data[0]?.package?.code === "package-pro") {
            setFeatureUnavailableModalOpen(true);
            form.setFieldsValue({ visitorPhoto: visitorWorkflowConfig?.visitorPhoto });
            return;
        }
        if (checked === false) {
            setVisitorPhotoEachDisabled(true);
            form.setFieldsValue({ visitorPhotoEach: false });
        } else {
            setVisitorPhotoEachDisabled(false);
        }
    };

    const handleVisitorPhotoEachChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential" ||
            subscriptions?.data[0]?.package?.code === "package-pro") {
            setFeatureUnavailableModalOpen(true);
            form.setFieldsValue({ visitorPhotoEach: visitorWorkflowConfig?.visitorPhotoEach });
        }
    };

    const locationIconStyle = {
        marginRight: '8px',
        width: '16px',
        height: '16px',
        filter: isDarkMode ? 'invert(1)' : 'none',
    };

    return (
        <>
            <OpForm
                form={form}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                hasError={false}
                defaultButtons={false}
                isReadOnly={!hasSigninconfWrite && hasSigninconfRead}
            >
                <OpSpace
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}
                >
                    <OpRow gutter={16}>
                        <OpCol span={12}>
                            <OpForm.Input label="Name" name="name" rules={[{ required: true, message: 'Please enter the name' }]} />
                        </OpCol>
                        <OpCol span={12}>
                            <OpForm.Input disabled label="Workflow Type" name="workflowType" />
                        </OpCol>
                    </OpRow>

                    <List
                        header={<div>VISITOR INFORMATION</div>}
                        dataSource={visitorInfoData}
                        bordered
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <OpForm.Switch
                                        name={item.name}
                                        style={{ marginBottom: 0 }}
                                        disabled={item.dependent && (isVisitorPhotoEachDisabled || (!hasSigninconfWrite && hasSigninconfRead))}
                                        onChange={item.name === 'visitorPhoto' ? handleVisitorPhotoChange : item.name === 'visitorPhotoEach' ? handleVisitorPhotoEachChange : undefined}
                                    />
                                    <span style={{ marginLeft: 8 }}>{item.label}</span>
                                </div>
                            </List.Item>
                        )}
                    />

                    <List
                        header={<div>NOTIFICATION</div>}
                        dataSource={notificationData}
                        bordered
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <OpForm.Switch
                                        name={item.name}
                                        style={{ marginBottom: 0 }}
                                        disabled={!hasSigninconfWrite && hasSigninconfRead}
                                    />
                                    <span style={{ marginLeft: 8 }}>{item.label}</span>
                                </div>
                            </List.Item>
                        )}
                    />

                    <List
                        header={<div>LOCATIONS</div>}
                        bordered
                        dataSource={visitorWorkflowLocations.data.filter(location => location.status === 1)}
                        renderItem={location => (
                            <List.Item>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <img src={locationIcon} alt="location icon" style={locationIconStyle} />
                                    <span>{location.name}</span>
                                </div>
                            </List.Item>
                        )}
                    />
                </OpSpace>
            </OpForm>

            <OpModal
                open={isFeatureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
};

export default WorkflowContent;
