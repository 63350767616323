import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';

const ContentForm: React.FC = () => {
    return (
        <>
            <OpForm.TextAreaInput rows={30} name="content" label="Content" />
        </>
    );
};

export default ContentForm;
