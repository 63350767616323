import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import { getStatusNameById, getStatusColor, getVisitorDateTime } from 'utils/visitorsHelper';
import { Bar } from 'react-chartjs-2';
import { AppDispatch, RootState } from 'store/store';
import { fetchVisits, setVisitsEndDate, setVisitsStartDate } from 'store/slices/visitsSlice';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { VISITORREPORTRS_TOOLTIP } from 'constants/tooltip';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { Visitor } from 'types/visitTypes';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { hasPermission } from 'utils/utils';
import { useNavigate } from 'react-router-dom';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

interface ExtendedVisitor extends Visitor {
    scheduleStart: string | null; // Ensuring each visitor has a scheduleStart
}

interface VisitorsTrendChartProps {
    visitors: ExtendedVisitor[];
    startDate: Dayjs;
    endDate: Dayjs;
}

function VisitorsTrendChart({ visitors, startDate, endDate }: VisitorsTrendChartProps) {
    const generateDateRange = (start: Dayjs, end: Dayjs) => {
        const dates = [];
        let currentDate = start;
        while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
            dates.push(currentDate.format('M/D'));
            currentDate = currentDate.add(1, 'day');
        }
        return dates;
    };

    const labels = generateDateRange(startDate, endDate);

    type StatusData = {
        [key: string]: {
            label: string;
            backgroundColor: string;
            borderColor: string;
            borderWidth: number;
            data: number[];
        };
    };

    const initialStatusData: StatusData = {
        'Pending': { label: 'Pending', backgroundColor: getStatusColor('Pending'), borderColor: getStatusColor('Pending'), borderWidth: 1, data: Array(labels.length).fill(0) },
        'Signed In': { label: 'Signed In', backgroundColor: getStatusColor('Signed In'), borderColor: getStatusColor('Signed In'), borderWidth: 1, data: Array(labels.length).fill(0) },
        'Signed Out': { label: 'Signed Out', backgroundColor: getStatusColor('Signed Out'), borderColor: getStatusColor('Signed Out'), borderWidth: 1, data: Array(labels.length).fill(0) },
        'Denied Entry': { label: 'Denied Entry', backgroundColor: getStatusColor('Denied Entry'), borderColor: getStatusColor('Denied Entry'), borderWidth: 1, data: Array(labels.length).fill(0) },
        'No Show': { label: 'No Show', backgroundColor: getStatusColor('No Show'), borderColor: getStatusColor('No Show'), borderWidth: 1, data: Array(labels.length).fill(0) }
    };

    const statusData = visitors.reduce<StatusData>((acc, visitor) => {
        const statusName = getStatusNameById(visitor.status!, visitor.scheduleStart);
        let dateKey;
        switch (statusName) {
            case 'Pending':
            case 'No Show':
                dateKey = visitor.scheduleStart;
                break;
            case 'Denied Entry':
            case 'Signed In':
                dateKey = visitor.signIn;
                break;
            case 'Signed Out':
                dateKey = visitor.signOut;
                break;
        }

        if (dateKey && dayjs(dateKey).isBetween(startDate, endDate, 'day', '[]')) {
            const dateLabel = dayjs(dateKey).format('M/D');
            const index = labels.indexOf(dateLabel);
            if (index !== -1) {
                acc[statusName].data[index]++;
            }
        }
        return acc;
    }, initialStatusData);

    const datasets = Object.values(statusData);

    const data = {
        labels,
        datasets
    };

    const [labelColor, setLabelColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
    const [gridColor, setGridColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));

    useEffect(() => {
        const handleThemeChange = () => {
            setLabelColor(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
            setGridColor(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));
        };

        // Listen for changes to the theme
        window.addEventListener('themechange', handleThemeChange);

        // Initial set
        handleThemeChange();

        return () => {
            window.removeEventListener('themechange', handleThemeChange);
        };
    }, []);

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
            x: {
                ticks: {
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
        },
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '280px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}

const VisitorReport: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const navigate = useNavigate();
    const { visits } = useSelector((state: RootState) => state.visits);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode); // Accessing isDarkMode from Redux

    const selectedLocationIdRef = useRef<number>(globalLocationId!);
    const startDateRef = useRef(dayjs().startOf('week').format(DATE_TIME_FORMAT));
    const endDateRef = useRef(dayjs().endOf('week').format(DATE_TIME_FORMAT));

    const [filteredVisitors, setFilteredVisitors] = useState<ExtendedVisitor[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    const columns: OpTableRawColumnType[] = [
        {
            label: 'DATE',
            dataIndex: ['date'],
            width: 150,
            render: (text: any) => dayjs(text).format('YYYY-MM-DD'), // Format date
            disabled: true,
        },
        {
            label: 'Pending',
            dataIndex: ['counts', 'Pending'],
            render: (text: any) => (
                <span style={{ color: text === 0 && isDarkMode ? 'grey' : text === 0 ? '#dddddd' : 'inherit' }}>
                    {text || 0}
                </span>
            ),
            width: 150,
        },
        {
            label: 'Signed In',
            dataIndex: ['counts', 'Signed In'],
            render: (text: any) => (
                <span style={{ color: text === 0 && isDarkMode ? 'grey' : text === 0 ? '#dddddd' : 'inherit' }}>
                    {text || 0}
                </span>
            ),
            width: 150,
        },
        {
            label: 'Signed Out',
            dataIndex: ['counts', 'Signed Out'],
            render: (text: any) => (
                <span style={{ color: text === 0 && isDarkMode ? 'grey' : text === 0 ? '#dddddd' : 'inherit' }}>
                    {text || 0}
                </span>
            ),
            width: 150,
        },
        {
            label: 'Denied Entry',
            dataIndex: ['counts', 'Denied Entry'],
            render: (text: any) => (
                <span style={{ color: text === 0 && isDarkMode ? 'grey' : text === 0 ? '#dddddd' : 'inherit' }}>
                    {text || 0}
                </span>
            ),
            width: 150,
        },
        {
            label: 'No Show',
            dataIndex: ['counts', 'No Show'],
            render: (text: any) => (
                <span style={{ color: text === 0 && isDarkMode ? 'grey' : text === 0 ? '#dddddd' : 'inherit' }}>
                    {text || 0}
                </span>
            ),
            width: 150
        },
        {
            label: 'TOTAL VISITORS',
            dataIndex: ['total'],
            key: 'total',
            width: 150,
            disabled: true,
        },
    ];

    const [visibleColumns, setVisibleColumns] = useState<Set<string>>(new Set(columns.map(col => col.label!))); // Initialize with all columns visible

    const fetchVisitsData = async () => {
        await dispatch(setVisitsStartDate(startDateRef.current));
        await dispatch(setVisitsEndDate(endDateRef.current));
        await dispatch(fetchVisits({ orgId }));
    };

    useEffect(() => {
        fetchVisitsData();
        // eslint-disable-next-line
    }, [dispatch, orgId]);

    useEffect(() => {
        if (visits) {
            const filteredVisitors = visits.reduce<ExtendedVisitor[]>((acc, visit) => {
                const visitorsWithSchedule = visit.visitors.map(visitor => {
                    const statusName = getStatusNameById(visitor.status!, visit.scheduleStart);
                    const dateKey = getVisitorDateTime(visitor, statusName, visit?.scheduleStart, DATE_TIME_FORMAT);

                    return {
                        ...visitor,
                        scheduleStart: visit.scheduleStart,
                        dateKey
                    };
                }).filter(visitor => {
                    return visitor.dateKey && dayjs(visitor.dateKey).isBetween(dayjs(startDateRef.current).startOf('day'), dayjs(endDateRef.current).endOf('day'), null, '[]');
                });

                return [...acc, ...visitorsWithSchedule];
            }, []);
            setFilteredVisitors(filteredVisitors);
        }
    }, [visits, globalUserId, hasAllvisitorsRead]);

    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        selectedLocationIdRef.current = locationId;
        startDateRef.current = startDate;
        endDateRef.current = endDate;
        fetchVisitsData();
    };

    const handleVisibleColumnsChange = (newVisibleColumns: Set<string>) => {
        setVisibleColumns(newVisibleColumns);
    };

    type StatusKey = 'Denied Entry' | 'Pending' | 'Signed In' | 'Signed Out' | 'No Show';

    type StatusCounts = Record<StatusKey, number>;

    interface TableRowData {
        key: string;
        date: string;
        total: number;
        counts: StatusCounts;
    }

    interface DateIndexMap {
        [key: string]: number;
    }

    const prepareTableData = (): TableRowData[] => {
        const dates = [];
        for (let d = dayjs(startDateRef.current); d.isSameOrBefore(dayjs(endDateRef.current)); d = d.add(1, 'day')) {
            dates.push(d.format('YYYY-MM-DD')); // Format date in YYYY-MM-DD
        }

        const tableData = dates.map(date => ({
            key: date,
            date: date,
            total: 0,
            counts: {
                'Denied Entry': 0,
                'Pending': 0,
                'Signed In': 0,
                'Signed Out': 0,
                'No Show': 0,
            }
        }));

        const dateIndexMap: DateIndexMap = tableData.reduce((acc: DateIndexMap, item, index) => {
            acc[item.date] = index;
            return acc;
        }, {});

        filteredVisitors.forEach(visitor => {
            const statusName = getStatusNameById(visitor.status!, visitor.scheduleStart) as StatusKey;
            let dateKey = '';
            switch (statusName) {
                case 'Pending':
                case 'No Show':
                    dateKey = dayjs(visitor.scheduleStart).format('YYYY-MM-DD');
                    break;
                case 'Denied Entry':
                case 'Signed In':
                    dateKey = dayjs(visitor.signIn).format('YYYY-MM-DD');
                    break;
                case 'Signed Out':
                    dateKey = dayjs(visitor.signOut).format('YYYY-MM-DD');
                    break;
            }
            if (dateKey && dateIndexMap[dateKey] !== undefined) {
                const rowIndex = dateIndexMap[dateKey];
                if (tableData[rowIndex].counts[statusName] !== undefined) {
                    tableData[rowIndex].counts[statusName]++;
                }
            }
        });

        tableData.forEach(row => {
            row.total = (Object.keys(row.counts) as StatusKey[]).reduce((acc, key) => {
                if (visibleColumns.has(key)) {
                    acc += row.counts[key];
                }
                return acc;
            }, 0);
        });

        return tableData.sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix()); // Sort in decreasing order by date
    };

    return (
        <OpPage title="Visitor Report" tooltip={VISITORREPORTRS_TOOLTIP} subtitle="">
            <OpSpace
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}
            >
                <DateRangeLocationFilter2
                    onDateRangeLocationFilter={handleDateRangeLocationFilter}
                    initialStartDate={startDateRef.current}
                    initialEndDate={endDateRef.current}
                />
                <OpCard type="inner" title="Visitors Trend">
                    <VisitorsTrendChart visitors={filteredVisitors} startDate={dayjs(startDateRef.current)} endDate={dayjs(endDateRef.current)} />
                </OpCard>
                <OpTable
                    dataSource={prepareTableData()}
                    label={`${dayjs(startDateRef.current).format(DATE_FORMAT)} - ${dayjs(endDateRef.current).format(DATE_FORMAT)}`}
                    columns={columns}
                    pagination={false}
                    allowGlobalSearch={false}
                    allowExport={true}
                    allowShowHideColumns={true}
                    onVisibleColumnsChange={handleVisibleColumnsChange}
                    rowActions={{
                        onEditClick: (data: any) => {
                            navigate('/visitors', {
                                state: { selectedDate: data.date }, // Pass the selected date
                            });
                        },
                    }}
                    rowKey={"key"}
                />
            </OpSpace>
        </OpPage>
    );
}

export default VisitorReport;
