import React from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { RootState } from 'store/store';
import { TABLE_HEIGHT } from 'constants/ui';
import { Agreement } from 'types/agreementTypes';
import { hasPermission } from 'utils/utils';
import { AGREEMENT_DELETE_ERROR, AGREEMENT_DELETE_SUCCESS, CONFIRM_DELETE_CONTENT, CONFIRM_DELETE_TITLE, NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from 'constants/messages';
import { deleteRequest, getRequest, postRequest } from 'api/apiClient';
import { AGREEMENT_DELETED_ID } from 'constants/userActivities';

interface AgreementsTableProps {
    agreements: Agreement[];
    loading: boolean;
    setAgreements: (agreement: Agreement[]) => void;
    handleEditClick: (agreement: Agreement) => void;
    handleAddClick: () => void;
}

const AgreementsTable: React.FC<AgreementsTableProps> = ({ agreements, loading, setAgreements, handleEditClick, handleAddClick }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAgreementsWrite = hasPermission(tokenScopeList, orgId, 'o', 'agreements:w');

    const handleDeleteAgreement = async (agreement: Agreement) => {
        try {
            await deleteRequest(`/orgs/${orgId}/termsAgreements/${agreement.id}`);
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: AGREEMENT_DELETED_ID,
                details: agreement.name
            });

            // Re-fetch the updated list of agreements
            const agreements = await getRequest(`/orgs/${orgId}/termsAgreements`);
            const filteredAgreements = agreements.data.filter((agreement: Agreement) => agreement.status === 1);
            setAgreements(filteredAgreements);

            notification.success({
                message: NOTIFICATION_SUCCESS,
                description: AGREEMENT_DELETE_SUCCESS,
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: AGREEMENT_DELETE_ERROR,
                placement: 'bottomRight',
            });
        }
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: "NAME",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: agreements,
        columns: columns,
        rowActions: {
            onEditClick: (agreement: Agreement) => { handleEditClick(agreement) },
            onDeleteClick: hasAgreementsWrite ? handleDeleteAgreement : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("Agreement"),
            deleteModalContent: ({ record }) => CONFIRM_DELETE_CONTENT(record.name),
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasAgreementsWrite,
        allowAddition: hasAgreementsWrite ? {
            itemName: 'Terms Agreement',
            onClick: () => handleAddClick(),
        } : false,
        loading: loading,
        allowExport: true,
        allowShowHideColumns: true,
    };

    return <OpTable {...opTableProps} />;
};

export default AgreementsTable;
