import React, { useState, useEffect, useRef } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { REDFLAGREPORTS_TOOLTIP } from 'constants/tooltip';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import RedFlagReportSummary from './RedFlagReportSummary';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import { RedFlag } from 'types/redFlagTypes';
import { fetchVisits, setSelectedVisit, setVisitsEndDate, setVisitsStartDate } from 'store/slices/visitsSlice';
import { Visitor } from 'types/visitTypes';
import { getStatusNameById, getVisitorDateTime } from 'utils/visitorsHelper';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { hasPermission } from 'utils/utils';
import STATUS from 'constants/status';
import DeniedVistiorsTable from './DeniedVistiorsTable';
import RedFlagsDrawer from 'components/pages/redFlag/RedFlagsDrawer';
import RedFlagsTable from './RedFlagsTable';
import { notification } from 'antd';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';
import { getRequest } from 'api/apiClient';

interface ExtendedVisitor extends Visitor {
    visitId: number;
};

const RedFlagReport: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);

    const [deniedVisitors, setDeniedVisitors] = useState<ExtendedVisitor[]>([]);
    const [filteredBlocklist, setFilteredBlocklist] = useState<RedFlag[]>([]);
    const [filteredWatchlist, setFilteredWatchlist] = useState<RedFlag[]>([]);

    const selectedLocationIdRef = useRef<number>(globalLocationId!);
    const startDateRef = useRef(dayjs().startOf('week').startOf('day').format(DATE_TIME_FORMAT));
    const endDateRef = useRef(dayjs().endOf('week').endOf('day').format(DATE_TIME_FORMAT));

    const [visitorsDrawerOpen, setVisitorsDrawerOpen] = useState(false);
    const [redFlagsDrawerOpen, setRedFlagsDrawerOpen] = useState(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
    const [activeTable, setActiveTable] = useState<'denied' | 'blockList' | 'watchList'>('denied');
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [selectedRedFlag, setSelectedRedFlag] = useState<RedFlag | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    const fetchVisitsData = async () => {
        await dispatch(setVisitsStartDate(startDateRef.current));
        await dispatch(setVisitsEndDate(endDateRef.current));
        await dispatch(fetchVisits({ orgId }));
    };

    useEffect(() => {
        if (orgId) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                    setRedFlags(redFlags.data);
                } catch (error) {
                    console.log("Failed to fetch data.");
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
            fetchVisitsData();
        }
        // eslint-disable-next-line 
    }, [dispatch, orgId]);

    useEffect(() => {
        if (!fetchVisitsLoading && !loading) {
            if (visits) {
                const filteredVisitors = visits.reduce<ExtendedVisitor[]>((acc, visit) => {
                    const extendedVisitor = visit.visitors
                        .filter(visitor => visitor.status === STATUS.DENIED_ENTRY.id && visitor.signIn)
                        .map(visitor => {
                            const statusName = getStatusNameById(visitor.status!, visit.scheduleStart);
                            const dateKey = getVisitorDateTime(visitor, statusName, visit?.scheduleStart, DATE_TIME_FORMAT);
                            return {
                                ...visitor,
                                visitId: visit.id,
                                dateKey
                            };
                        }).filter(visitor => visitor && dayjs(visitor.dateKey).isBetween(dayjs(startDateRef.current).startOf('day'), dayjs(endDateRef.current).endOf('day'), null, '[]'));
                    return [...acc, ...extendedVisitor];
                }, []);
                setDeniedVisitors(filteredVisitors);
            }

            if (redFlags) {
                const filteredRedFlags = redFlags.filter(flag =>
                    dayjs(flag.effectiveDate).utc(true).local().isBetween(dayjs(startDateRef.current), dayjs(endDateRef.current), null, '[]')
                );

                const blockList = filteredRedFlags.filter(flag => flag.level === 1);
                const watchList = filteredRedFlags.filter(flag => flag.level === 2);

                setFilteredBlocklist(blockList);
                setFilteredWatchlist(watchList);
            }

            setIsTableLoading(false);
        }
    }, [visits, redFlags, fetchVisitsLoading, loading, globalUserId, hasAllvisitorsRead]);

    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        selectedLocationIdRef.current = locationId;
        startDateRef.current = startDate;
        endDateRef.current = endDate;
        fetchVisitsData();
    };

    const handleEditClick = (redFlag: RedFlag) => {
        setSelectedRedFlag(redFlag);
        setRedFlagsDrawerOpen(true);
    };

    const showNotification = (message: string) => {
        notification.info({
            message,
            placement: 'bottomRight',
        });
    };

    const handleDeniedClick = () => {
        showNotification('Displaying Denied Visitors.');
        setActiveTable('denied');
    };
    const handleBlockListClick = () => {
        showNotification('Displaying Block List.');
        setActiveTable('blockList');
    };
    const handleWatchListClick = () => {
        showNotification('Displaying Watch List.');
        setActiveTable('watchList');
    };

    const handleVisitorsDrawerClose = () => {
        setVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    const handleRedFlagsDrawerClose = () => {
        setRedFlagsDrawerOpen(false);
        setSelectedRedFlag(null);
    };

    return (
        <OpPage title="Red Flag Report" tooltip={REDFLAGREPORTS_TOOLTIP}>
            <OpSpace
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <DateRangeLocationFilter2
                    onDateRangeLocationFilter={handleDateRangeLocationFilter}
                    initialStartDate={startDateRef.current}
                    initialEndDate={endDateRef.current}
                />
                <RedFlagReportSummary
                    deniedVisitors={deniedVisitors}
                    blockList={filteredBlocklist}
                    watchList={filteredWatchlist}
                    onDeniedClick={handleDeniedClick}
                    onBlockListClick={handleBlockListClick}
                    onWatchListClick={handleWatchListClick}
                />
                {activeTable === 'denied' && !isTableLoading && <DeniedVistiorsTable deniedVisitors={deniedVisitors} setDrawerVisible={setVisitorsDrawerOpen} />}
                {activeTable === 'blockList' && !isTableLoading && <RedFlagsTable redFlags={filteredBlocklist} handleEditClick={handleEditClick} isBlockList={true} />}
                {activeTable === 'watchList' && !isTableLoading && <RedFlagsTable redFlags={filteredWatchlist} handleEditClick={handleEditClick} isBlockList={false} />}
            </OpSpace>

            {(visitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={visitorsDrawerOpen}
                    redFlags={redFlags}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleVisitorsDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}

            {(redFlagsDrawerOpen) && (
                <RedFlagsDrawer
                    open={redFlagsDrawerOpen}
                    selectedRedFlag={selectedRedFlag}
                    onClose={handleRedFlagsDrawerClose}
                    setRedFlags={setRedFlags}
                />
            )}
        </OpPage>
    );
}

export default RedFlagReport;
