import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Spin, Alert, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useEvent } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { setSelectedVisit } from 'store/slices/visitsSlice';

interface ScanQrCodeProps {
    open: boolean;
    onClose: () => void;
    openVisitorsDrawer: () => void;
}

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const ScanQRCodeModal: React.FC<ScanQrCodeProps> = ({
    open,
    onClose,
    openVisitorsDrawer,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { visitsToday } = useSelector((state: RootState) => state.visits);

    const scannedDataRef = useRef('');
    const [scanningInProgress, setScanningInProgress] = useState(false);
    const [isKeyPressed, setIsKeyPressed] = useState(false);

    const timerRef = useRef<NodeJS.Timeout | null>(null); // Timer to detect the end of scanning

    useEvent('keydown', scanningInProgress ? (event: Event) => handleKeyDown(event as KeyboardEvent) : null, document);

    useEffect(() => {
        if (open) {
            setScanningInProgress(true);
        } else {
            setScanningInProgress(false);
            setIsKeyPressed(false);
            scannedDataRef.current = '';
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }
    }, [open]);

    const handleScannedData = useCallback(async (data: string) => {
        console.log('Scanned Data:', data);

        try {
            const response = await fetch(`${apiUrl}/orgs/${orgId}/search/registration?token=${data}`, {
                method: 'GET',
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });

            console.log("response", response);
            if (response.ok) {
                const result = await response.json();
                const selectedVisit = visitsToday.find(v => Number(v.id) === Number(result.data[0].id));
                console.log("visitsToday", visitsToday);
                console.log("result", result);
                console.log("selectedVisit", selectedVisit);

                if (selectedVisit === undefined) {
                    notification.error({
                        message: 'Expired QR Code',
                        description: 'The QR code you are trying to use has expired. Please request a new QR code and try again.',
                        placement: 'bottomRight',
                    });
                } else {
                    dispatch(setSelectedVisit(selectedVisit!));
                    openVisitorsDrawer();
                }
            } else {
                notification.error({
                    message: 'Invalid QR Code',
                    description: 'Could not scan the QR code. Please try again.',
                    placement: 'bottomRight',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Invalid QR Code',
                description: 'Could not scan the QR code. Please try again.',
                placement: 'bottomRight',
            });
            console.error('Request failed:', error);
        }
    }, [dispatch, openVisitorsDrawer, orgId, visitsToday]);


    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        setIsKeyPressed(true);

        if (event.key.length === 1) {
            scannedDataRef.current += event.key;
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        // console.log("scannedDataRef", scannedDataRef);
        timerRef.current = setTimeout(() => {
            setScanningInProgress(false);
            setIsKeyPressed(false);
            handleScannedData(scannedDataRef.current);
            scannedDataRef.current = '';
            onClose();
        }, 1000);
    }, [onClose, handleScannedData]);

    return (
        <Modal
            title="Scan QR Code to Sign In Visitor"
            open={open}
            onOk={onClose}
            onCancel={() => {
                scannedDataRef.current = '';
                setScanningInProgress(false);
                setIsKeyPressed(false);
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = null;
                }
                onClose();
            }}
            okText="Confirm"
            cancelText="Cancel"
            centered
            style={{ textAlign: 'center' }}
            footer={null}
        >
            {scanningInProgress ? <Spin indicator={<LoadingOutlined spin />} size="default" style={{ padding: '20px' }} /> : undefined}
            {isKeyPressed ? <Alert message="Processing..." type="success" /> : <Alert message="Waiting to Scan QR Code" type="info" />}
        </Modal>
    );
};

export default ScanQRCodeModal;
