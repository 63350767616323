import { Button, Form, Input, notification } from "antd";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { clearError } from "store/slices/authSlice";
import { LoginPayload } from "types/authTypes";
import type { InputRef } from 'antd';

interface LoginFormProps {
    onFinish: (values: LoginPayload) => void;
    error: string | null;
    setIsForgotPassword: (isForgotPassword: boolean) => void;
    setIsAlternateLogin: (isAlternateLogin: boolean) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onFinish, error, setIsForgotPassword, setIsAlternateLogin }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const emailInputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (error) {
            notification.error({
                message: 'Login Error',
                description: error,
                placement: 'bottomRight',
            });
            dispatch(clearError());
        }
    }, [dispatch, error]);

    return (
        <>
            <Form
                form={form}
                className="login-form"
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: 'Email is required' },
                        { type: 'email', message: 'Please enter a valid email address' },
                    ]}
                    style={{ marginBottom: '24px' }}
                >
                    <Input autoComplete="off" />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: 'Password is required' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    style={{ marginBottom: '6px' }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="button"
                        style={{ paddingBottom: '0px' }}
                    >
                        Sign in
                    </Button>
                </Form.Item>
            </Form>
            <div>
                <Button
                    onClick={() => setIsForgotPassword(true)}
                    type="link"
                    className="button"
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    {'Forgot password?'}
                </Button>
                <Button
                    onClick={() => setIsAlternateLogin(true)}
                    className="button"
                    style={{ marginTop: '30px' }}
                >
                    Alternative Login
                </Button>
            </div>
        </>
    );
};

export default LoginForm;
