import React, { useState } from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { TransferProps, GetProp, TableColumnsType, Space } from 'antd';
import { formatFullName, hasPermission } from 'utils/utils';
import { User } from 'types/userTypes';
import { profileIcon } from 'utils/visitorsHelper';
import { TableTransfer } from './UserTableTransfer';

type TransferItem = GetProp<TransferProps, 'dataSource'>[number];

interface UserFormProps {
    users: User[];
    usersInRole: User[];
}

const UserForm: React.FC<UserFormProps> = ({ users, usersInRole }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');

    const [targetKeys, setTargetKeys] = useState<React.Key[]>(usersInRole.map((user: User) => user.id) || []);

    const columns: TableColumnsType<TransferItem> = [
        {
            dataIndex: 'fullName',
            title: 'Name',
            render: (text, record) => (
                <Space>
                    {profileIcon({
                        fullName: record.fullName,
                        size: 24,
                        color: isDarkMode ? '#D3D3D3' : '#1f93ff',
                    })}
                    {text}
                </Space>
            ),
        },
        {
            dataIndex: 'email',
            title: 'Email',
        },
    ];

    return (
        <OpForm.Item name="users">
            <TableTransfer
                dataSource={users.map((user) => ({
                    key: user.id,
                    fullName: formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName),
                    email: user.identity.email || '',
                }))}
                titles={['Available Users', 'Users in Role']}
                targetKeys={targetKeys}
                showSearch
                showSelectAll={false}
                onChange={(nextTargetKeys) => setTargetKeys(nextTargetKeys)}
                filterOption={(inputValue, item) =>
                    item.fullName.toLowerCase().includes(inputValue.toLowerCase()) ||
                    item.email.toLowerCase().includes(inputValue.toLowerCase())
                }
                leftColumns={columns}
                rightColumns={columns}
                disabled={!hasRoleWrite}
            />
        </OpForm.Item>
    );
};

export default UserForm;
