import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import FeedbackModal from 'components/layout/sideMenu/FeedbackModal';
// import MobileAppModal from 'components/layout/sideMenu/MobileAppModal';

const Support: React.FC = () => {
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    // const [mobileAppModalOpen, setMobileAppModalOpen] = useState(false);

    const handleUserManualClick = () => {
        window.open("https://commaowl.com/?83FJ6X", "_blank");
    };

    const handleFeedbackClick = () => {
        setFeedbackModalOpen(true);
    };

    const handleReleaseNotesClick = () => {
        window.open("https://commaowl.com/?MQHYZQ", "_blank");
    };

    // const handleMobileAppClick = () => {
    //     setMobileAppModalOpen(true);
    // };

    const boxStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '18px',
        border: '1px solid #ccc',
        borderRadius: '16px',
        cursor: 'pointer',
        transition: 'border-color 0.3s ease',
    };

    return (
        <OpPage title="Support">
            <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
                <div
                    style={boxStyle}
                    onClick={handleUserManualClick}
                    onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#006ed7')}
                    onMouseLeave={(e) => (e.currentTarget.style.borderColor = '#ccc')}
                >
                    <div style={{ marginLeft: '16px' }}>
                        <strong>User Manual</strong>
                    </div>
                </div>
                <div
                    style={boxStyle}
                    onClick={handleFeedbackClick}
                    onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#006ed7')}
                    onMouseLeave={(e) => (e.currentTarget.style.borderColor = '#ccc')}
                >
                    <div style={{ marginLeft: '16px' }}>
                        <strong>Send Feedback</strong>
                    </div>
                </div>
                <div
                    style={boxStyle}
                    onClick={handleReleaseNotesClick}
                    onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#006ed7')}
                    onMouseLeave={(e) => (e.currentTarget.style.borderColor = '#ccc')}
                >
                    <div style={{ marginLeft: '16px' }}>
                        <strong>Release Notes</strong>
                    </div>
                </div>
                {/* <div
                    style={boxStyle}
                    onClick={handleMobileAppClick}
                    onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#006ed7')}
                    onMouseLeave={(e) => (e.currentTarget.style.borderColor = '#ccc')}
                >
                    <div style={{ marginLeft: '16px' }}>
                        <strong>Mobile App</strong>
                    </div>
                </div> */}
            </OpSpace>

            <FeedbackModal
                open={feedbackModalOpen}
                onClose={() => setFeedbackModalOpen(false)}
            />

            {/* <MobileAppModal
                open={mobileAppModalOpen}
                onClose={() => setMobileAppModalOpen(false)}
            /> */}
        </OpPage>
    );
};

export default Support;
