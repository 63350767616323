import React from 'react';
import { Button, Form, Typography } from 'antd';

const { Title, Text } = Typography;

interface AlternateLoginProps {
    onBack: () => void;
    onAvigilonClick: () => void;
}

const AlternateLogin: React.FC<AlternateLoginProps> = ({ onBack, onAvigilonClick }) => {
    return (
        <>
            <Form
                layout="vertical"
                onFinish={onAvigilonClick}
                style={{ maxWidth: '400px', margin: '0 auto' }}
            >
                <Title level={4} style={{ textAlign: 'center' }}>
                    Alternate Login
                </Title>
                <Text style={{ display: 'block', textAlign: 'center', marginBottom: '24px' }}>
                    Log in using the integrated system credentials.
                </Text>


                <Form.Item style={{ marginBottom: '24px' }}>
                    <Button
                        htmlType="submit"
                        block
                    >
                        Sign in using Avigilon Alta
                    </Button>
                </Form.Item>
            </Form >
            <Button
                onClick={onBack}
                type="link"
                className="button"
                style={{ fontWeight: 'bold' }}
            >
                {'Return to sign in'}
            </Button>
        </>
    );
};

export default AlternateLogin;
