
import dayjs from 'dayjs';

export const capitalizeName = (name: string | null) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const formatFullName = (firstName: string | null, middleName: string | null, lastName: string | null) => {
    const first = capitalizeName(firstName)?.trim();
    const middle = capitalizeName(middleName)?.trim();
    const last = capitalizeName(lastName)?.trim();
    return [first, middle, last].filter(Boolean).join(' ');
};

export function transformNullToEmptyString(obj: Record<string, any>): Record<string, any> {
    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];

        // Check if the value is a Day.js object
        if (dayjs.isDayjs(value)) {
            acc[key] = value; // Keep the Day.js object as is
        } else if (value === null) {
            acc[key] = ''; // Replace null with an empty string
        } else if (value === true) {
            acc[key] = 1; // Replace true with 1
        } else if (value === false) {
            acc[key] = 0; // Replace false with 0
        } else if (typeof value === 'object' && !Array.isArray(value)) {
            // If the value is an object (but not an array), recursively transform it
            acc[key] = transformNullToEmptyString(value);
        } else {
            acc[key] = value; // Keep the value as is
        }
        return acc;
    }, {} as Record<string, any>);
}

export const hasPermission = (tokenScopeList: any[], orgId: number, prefix: 'o' | 's', permission: string) => {
    const orgPermissionPrefix = `${prefix}${orgId}-`;
    const requiredPermission = orgPermissionPrefix + permission;

    // Filter the scopes that belong to the given orgId
    const orgScopes = tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId));
    if (orgScopes) {
        return orgScopes.scope.includes(requiredPermission);
    }

    return false;
};
