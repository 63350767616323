import React, { useEffect, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { ACTIVITYLOG_TOOLTIP } from 'constants/tooltip';
import ActivityLogTable from './ActivityLogTable';
import ActivityLogDrawer from './ActivityLogDrawer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { UserActivity } from 'types/userActivityTypes';
import { getRequest } from 'api/apiClient';

const ActivityLog: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [userActivities, setUserActivities] = useState<UserActivity[]>([]);
    const [selectedUserActivitiy, setSelectedUserActivitiy] = useState<UserActivity | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userActivities = await getRequest(`/orgs/${orgId}/userActivity`);
                setUserActivities(userActivities.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    const handleEditClick = (userActivitiy: UserActivity) => {
        setSelectedUserActivitiy(userActivitiy);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedUserActivitiy(null);
    };

    return (
        <OpPage title="Activity Log" tooltip={ACTIVITYLOG_TOOLTIP} subtitle="">
            <ActivityLogTable
                userActivities={userActivities}
                loading={loading}
                handleEditClick={handleEditClick}
            />
            <ActivityLogDrawer
                open={drawerOpen}
                selectedUserActivitiy={selectedUserActivitiy}
                onClose={handleDrawerClose}
            />
        </OpPage>
    );
}

export default ActivityLog;
