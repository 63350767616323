import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { Agreement } from 'types/agreementTypes';
import { List } from 'antd';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/utils';
import { RootState } from 'store/store';

const availability = [
    { label: 'For Invitations', name: 'forInvitation', valuePropName: 'checked' },
    { label: 'For Sign In', name: 'forSignIn', valuePropName: 'checked' },
];

interface AgreementFormProps {
    selectedAgreement: Agreement | null;
    type: string;
    setType: (type: string) => void;
}

const AgreementForm: React.FC<AgreementFormProps> = ({ selectedAgreement, type, setType }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAgreementsRead = hasPermission(tokenScopeList, orgId, 'o', 'agreements:r');
    const hasAgreementsWrite = hasPermission(tokenScopeList, orgId, 'o', 'agreements:w');

    return (
        <>
            <OpForm.Input label="Name" name="name" rules={[{ required: true, message: 'Please enter the agreement name' }]} />
            <OpForm.Input label="Description" name="description" />
            {!selectedAgreement && (
                <OpForm.Select
                    name="type" label="Type"
                    onChange={(value) => setType(value as string)}
                    options={[
                        { label: "Text", value: "text" },
                        // { label: "URL", value: "url" }
                    ]}
                />
            )}
            {type === 'url' && (
                <OpForm.Input name="content" label="Document URL" />
            )}
            {selectedAgreement && (
                <OpForm.Input disabled label="Updated At" name="updatedAt" />
            )}
            <List
                header={<div>AVAILABILITY</div>}
                dataSource={availability}
                bordered
                renderItem={item => (
                    <List.Item>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <OpForm.Switch
                                name={item.name} style={{ marginBottom: 0 }}
                                disabled={!hasAgreementsWrite && hasAgreementsRead} />
                            <span style={{ marginLeft: 8 }}>{item.label}</span>
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
};

export default AgreementForm;
