import React, { useEffect, useRef, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import VisitorsDrawer from './drawer/VisitorsDrawer';
import VisitorsTable from './VisitorsTable';
import { VISITORS_TOOLTIP } from 'constants/tooltip';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from 'api/apiClient';
import DateRangeLocationFilter from 'components/customAntd/DateRangeLocationFilter';
import dayjs from 'dayjs';
import { RedFlag } from 'types/redFlagTypes';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { fetchVisits, setVisitsEndDate, setSelectedVisit, setVisitsStartDate } from 'store/slices/visitsSlice';
import { useLocation } from 'react-router-dom';

const Visitors: React.FC = () => {
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);

    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Check if a date is passed from the navigation
    const selectedDateFromState = location.state?.selectedDate;

    const selectedLocationIdRef = useRef<number>(globalLocationId!);
    const startDateRef = useRef(
        selectedDateFromState
            ? dayjs(selectedDateFromState).startOf('day').format(DATE_TIME_FORMAT)
            : dayjs().startOf('day').format(DATE_TIME_FORMAT)
    );
    const endDateRef = useRef(
        selectedDateFromState
            ? dayjs(selectedDateFromState).endOf('day').format(DATE_TIME_FORMAT)
            : dayjs().endOf('day').format(DATE_TIME_FORMAT)
    );
    
    const fetchVisitsData = async () => {
        setLoading(true);
        await dispatch(setVisitsStartDate(startDateRef.current));
        await dispatch(setVisitsEndDate(endDateRef.current));
        await dispatch(fetchVisits({ orgId }));
        setLoading(false);
    };

    // Fetch visits and red flags
    useEffect(() => {
        const fetchRedFlags = async () => {
            setLoading(true);
            try {
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchVisitsData();
        fetchRedFlags();
        // eslint-disable-next-line
    }, [orgId]);

    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        selectedLocationIdRef.current = locationId;
        startDateRef.current = startDate;
        endDateRef.current = endDate;
        fetchVisitsData();
    };

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    return (
        <OpPage title="Visitors" tooltip={VISITORS_TOOLTIP}>
            <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
                <DateRangeLocationFilter
                    onDateRangeLocationFilter={handleDateRangeLocationFilter}
                    initialStartDate={startDateRef.current}
                    initialEndDate={endDateRef.current}
                />
                <VisitorsTable
                    redFlags={redFlags}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openProfileDrawer={() => setIsProfileDrawerOpen(true)}
                    loading={loading}
                />
            </OpSpace>
            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    redFlags={redFlags}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </OpPage>
    );
}

export default Visitors;
