import React, { useEffect, useState } from 'react';
import { Button, Form, Layout, notification, Spin } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ResetPassword.scss';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';

const { Content } = Layout;
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const ResetPassword: React.FC = () => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [orgId, setOrgId] = useState<number>();
    const [userId, setUserId] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
    const { token } = useParams();
    const [form] = OpForm.useForm();

    useEffect(() => {
        setIsLoading(true);
        if (token) {
            axios.get(`${apiUrl}/auth/accessTokens/${token}`)
                .then(response => {
                    if (response) {
                        setIsValid(true);
                        setOrgId(response.data.data[0].tokenScopeList[0].org.id);
                        setUserId(response.data.data[0].tokenScopeList[0].user.id);
                    }
                })
                .catch(error => {
                    console.error('Token validation error:', error);
                    setIsValid(false);
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [token]);

    const handleSubmit = (async ({ values }: IOnSubmitArgs<any>) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/users/${userId}`,
                {
                    identity: {
                        password: values.newPassword
                    }
                },
                {
                    headers: {
                        Authorization: `${token}`
                    }
                }
            );
            notification.success({
                message: 'Success',
                description: 'Your password has been changed successfully.',
                placement: 'bottomRight'
            });
            setIsPasswordReset(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            notification.error({
                message: 'Error',
                description: 'An error occurred while changing your password. Please try again.',
                placement: 'bottomRight'
            });
        }
    });

    return (
        <Layout className="reset-password-layout">
            <Content className="reset-password-content">
                <img src="/images/invisit_main.png" alt="Logo" className="logo" />
                {isLoading ? (
                    <div className="loading-container">
                        <Spin />
                    </div>
                ) : isPasswordReset ? (
                    <div className="success-message">
                        Your password has been saved.
                    </div>

                ) : isValid ? (
                    <OpForm
                        form={form}
                        name="exampleForm"
                        layout="vertical"
                        onSubmit={handleSubmit}
                        hasError={false}
                        defaultButtons={false}
                    >
                        <OpForm.PasswordInput
                            name='newPassword'
                            label='Reset Password'
                            placeholder="Enter new password"
                            rules={[
                                { required: true, message: 'Please enter your password' },
                                { min: 6, message: 'Password must be at least 6 characters' }
                            ]}
                        />
                        <div className="password-instructions">
                            Please enter at least 6 characters for your new password.
                        </div>
                        <OpDivider />
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>

                    </OpForm>
                ) : (
                    <div className="message-box">
                        <span>Invalid or expired link.</span>
                    </div>
                )}
            </Content>

            <footer className="reset-password-footer">
                &copy; 2024 Invisit, LLC
            </footer>
        </Layout>
    );
};

export default ResetPassword;
