import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';

const RoleForm: React.FC = () => {
    return (
        <>
            <OpForm.Input
                label='Name'
                name='name'
                rules={[{ required: true, message: 'Please enter the role name' }]}
            />
            <OpForm.Input label='Description' name='description' />
        </>
    );
};

export default RoleForm;
