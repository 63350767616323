import React, { useEffect, useRef, useState } from 'react';
import { Modal, Input, message } from 'antd';
import type { InputRef } from 'antd';

interface PinModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (pin: string) => Promise<boolean>;
}

const PinModal: React.FC<PinModalProps> = ({ open, onClose, onSubmit }) => {
    const [pin, setPin] = useState<string[]>(['', '', '', '']);
    const [isError, setIsError] = useState<boolean>(false);
    const inputsRef = useRef<(InputRef | null)[]>([]);

    // Focus on the first input field when the modal opens
    useEffect(() => {
        if (open && inputsRef.current[0]) {
            inputsRef.current[0]?.focus();
        }
    }, [open]);

    const handleChange = (value: string, index: number) => {
        if (!/^\d?$/.test(value)) return; // Allow only numeric input

        const newPin = [...pin];
        newPin[index] = value;
        setPin(newPin);

        // Move to the next input if filled
        if (value && index < 3) {
            inputsRef.current[index + 1]?.focus();
        }

        // Validate PIN if all inputs are filled
        if (newPin.every((digit) => digit)) {
            validatePin(newPin.join(''));
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !pin[index] && index > 0) {
            // Move to the previous input on Backspace if the current input is empty
            inputsRef.current[index - 1]?.focus();
        }
    };

    const validatePin = async (enteredPin: string) => {
        try {
            const isValid = await onSubmit(enteredPin);
            if (!isValid) {
                setIsError(true);
                setPin(['', '', '', '']);
                message.error('Invalid PIN. Please try again.');
                inputsRef.current[0]?.focus();
            } else {
                setIsError(false);
                message.success('PIN validated successfully.');
                onClose();
            }
        } catch (error) {
            console.error('Error validating PIN:', error);
        }
    };

    return (
        <Modal
            title="Guest Pass PIN"
            open={open}
            onCancel={onClose}
            footer={null}
            centered
        >
            <p>This Guest Pass requires a PIN, that was provided to you with the link. Please enter below.</p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', marginTop: '16px' }}>
                {pin.map((digit, index) => (
                    <Input
                        key={index}
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputsRef.current[index] = el)}
                        maxLength={1}
                        inputMode="numeric"
                        pattern="\d*"
                        style={{
                            width: '50px',
                            height: '50px',
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            borderColor: isError ? 'red' : undefined,
                        }}
                    />
                ))}
            </div>
        </Modal>
    );
};

export default PinModal;
