import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { DATE_FORMAT } from 'constants/dates';

const RedFlagForm: React.FC = () => {
    return (
        <>
            <OpForm.Select
                label="Red Flag type" name="level" rules={[{ required: true, message: 'Please select the level' }]}
                placeholder="Select type"
                options={[
                    { label: <span><StopOutlined style={{ color: 'rgba(242,103,87,1.0)' }} /> Blocklist</span>, value: 1 },
                    { label: <span><EyeOutlined style={{ color: '#FFD54F' }} /> Watchlist</span>, value: 2 }
                ]}
            />
            <OpForm.Input label="Effective Date" name="effectiveDate" disabled={true} />
            <OpForm.DatePicker format={DATE_FORMAT} label="Expire Date" name="expireDate" />
        </>
    );
};

export default RedFlagForm;
