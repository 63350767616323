import React from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { HOSTREPORTS_TOOLTIP } from 'constants/tooltip';
import HostReportTable from './HostReportTable';

const HostReport: React.FC = () => {
    return (
        <OpPage title="Host Report" tooltip={HOSTREPORTS_TOOLTIP}>
            <HostReportTable />
        </OpPage>
    );
}

export default HostReport;
