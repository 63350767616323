import React, { useEffect, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import RedFlagsTable from './RedFlagTable';
import RedFlagOverView from './RedFlagOverView';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import RedFlagsDrawer from './RedFlagsDrawer';
import { REDFLAG_TOOLTIP } from 'constants/tooltip';
import { getRequest } from 'api/apiClient';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { RedFlag, RedFlagCount } from 'types/redFlagTypes';

const RedFlags: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [selectedRedFlag, setSelectedRedFlag] = useState<RedFlag | null>(null);
    const [blockCount, setBlockCount] = useState<number>(0);
    const [watchCount, setWatchCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);

                const redFlagCount = await getRequest(`/orgs/${orgId}/redflagCount`);
                const blockItem = redFlagCount.data.find((item: RedFlagCount) => item.level === 1);
                const watchItem = redFlagCount.data.find((item: RedFlagCount) => item.level === 2);

                setBlockCount(blockItem?.count ? blockItem.count : 0);
                setWatchCount(watchItem?.count ? watchItem.count : 0);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    const handleEditClick = (redFlag: RedFlag) => {
        setSelectedRedFlag(redFlag);
        setDrawerOpen(true);
    };

    const handleAddClick = () => {
        setSelectedRedFlag(null);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedRedFlag(null);
    };

    return (
        <OpPage title="Red Flag" tooltip={REDFLAG_TOOLTIP} subtitle="">
            <OpSpace
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <RedFlagOverView
                    blockCount={blockCount}
                    watchCount={watchCount}
                    loading={loading}
                />
                <RedFlagsTable
                    redFlags={redFlags}
                    loading={loading}
                    handleEditClick={handleEditClick}
                    handleAddClick={handleAddClick}
                />
            </OpSpace>

            <RedFlagsDrawer
                open={drawerOpen}
                selectedRedFlag={selectedRedFlag}
                onClose={handleDrawerClose}
                setRedFlags={setRedFlags}
                setBlockCount={setBlockCount}
                setWatchCount={setWatchCount}
            />
        </OpPage>
    );
}

export default RedFlags;
