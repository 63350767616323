import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getRequest } from 'api/apiClient';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import dayjs from 'dayjs';
import { Empty, List, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { GuestPass, GuestPassLog } from 'types/guestPassTypes';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { Store } from 'antd/es/form/interface';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { formatFullName } from 'utils/utils';
import { getStatusColor, getStatusNameById } from 'utils/visitorsHelper';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';

const GuestPassLogTab: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);
    const triggerUpdate = useSelector((state: RootState) => state.guestPass.triggerUpdate);

    const [guestPasses, setGuestPasses] = useState<GuestPass[]>([]);
    const [guestPassLogs, setGuestPassLogs] = useState<Record<number, GuestPassLog[]>>({});
    const [msiAltaEntries, setMsiAltaEntries] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedLog, setSelectedLog] = useState<GuestPassLog | null>(null);

    useEffect(() => {
        const fetchLogs = async () => {
            setLoading(true);
            try {
                const startDate = dayjs(selectedVisit?.scheduleStart || dayjs()).subtract(30, 'days').format(DATE_TIME_FORMAT);
                const endDate = dayjs(selectedVisit?.scheduleEnd || dayjs()).add(30, 'days').format(DATE_TIME_FORMAT);

                // Fetch guest passes
                const guestPassResponse = await getRequest(`/orgs/${orgId}/msiAltaGuestPassByDate`, {
                    startDate,
                    endDate,
                    visitId: selectedVisit?.id,
                });
                const fetchedGuestPasses = guestPassResponse.data;
                setGuestPasses(fetchedGuestPasses);

                // Fetch logs for each guest pass
                const logsByGuestPass: Record<number, GuestPassLog[]> = {};
                for (const pass of fetchedGuestPasses) {
                    const logResponse = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/msiAltaGuestPass/${pass.id}/log`);
                    logsByGuestPass[pass.id] = logResponse.data.sort((a: GuestPassLog, b: GuestPassLog) =>
                        dayjs(b.requestedUtc).diff(dayjs(a.requestedUtc))
                    );
                }
                setGuestPassLogs(logsByGuestPass);

                // Fetch entry names
                const entriesResponse = await getRequest(`/orgs/${orgId}/integrationMsiAltaEntry`);
                setMsiAltaEntries(entriesResponse.data);
            } catch (error) {
                console.error('Failed to fetch logs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLogs();
    }, [orgId, selectedVisit?.id, triggerUpdate, selectedVisit?.scheduleEnd, selectedVisit?.scheduleStart]);

    const isGuestPassValid = (guestPass: GuestPass): boolean => {
        if (!guestPass?.startUtc || !guestPass?.endUtc) return false;
        const now = dayjs.utc();
        const start = dayjs.utc(guestPass.startUtc);
        const end = dayjs.utc(guestPass.endUtc);
        return now.isAfter(start) && now.isBefore(end) && guestPass.status === 1;
    };

    const getEntryName = (entryId: number): string => {
        const entry = msiAltaEntries.find((entry) => entry.id === entryId);
        return entry ? entry.name : 'Unknown Entry';
    };

    return (
        <>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined spin />} />
                </div>
            ) : guestPasses.length === 0 ? ( // Check if guestPasses is empty
                <Empty description="No Data" />
            ) : (
                <OpSpace
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}
                >
                    {guestPasses
                        .sort((a, b) => {
                            const validA = isGuestPassValid(a);
                            const validB = isGuestPassValid(b);
                            if (validA === validB) {
                                return dayjs(b.startUtc).diff(dayjs(a.startUtc)); // Sort by start date if both valid/invalid
                            }
                            return validA ? -1 : 1; // Valid passes first
                        })
                        .map((guestPass) => (
                            <div key={guestPass.id}>
                                <List
                                    header={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>
                                                Guest Pass issued : {dayjs(guestPass.createdAt).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)}
                                            </span>
                                            <span
                                                style={{
                                                    marginLeft: '16px',
                                                    fontWeight: 'bold',
                                                    color: isGuestPassValid(guestPass) ? 'green' : 'var(--colorTextDisabled)',
                                                }}
                                            >
                                                {isGuestPassValid(guestPass) ? 'Valid' : 'Invalid'}
                                            </span>
                                        </div>
                                    }
                                    dataSource={guestPassLogs[guestPass.id] || []}
                                    bordered
                                    renderItem={(log) => (
                                        <List.Item onClick={() => setSelectedLog(log)} style={{ cursor: 'pointer' }}>
                                            <div style={{ flex: 1 }}>{dayjs(log.requestedUtc).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)}</div>
                                            <div style={{ flex: 1 }}>{getEntryName(log.entryId)}</div>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    color: log.success ? 'green' : 'var(--colorError)',
                                                }}
                                            >
                                                {log.success ? 'Success' : 'Failed'}
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        ))}
                </OpSpace>
            )}
            {selectedLog && (
                <OpModal
                    title="Log Details"
                    open={!!selectedLog}
                    onCancel={() => setSelectedLog(null)}
                    footer={null}
                >
                    <OpForm
                        initialValues={{
                            visitor: formatFullName(selectedVisit?.visitors[0]?.firstName || null, selectedVisit?.visitors[0]?.middleName || null, selectedVisit?.visitors[0]?.lastName || null)
                                + (selectedVisit?.visitors.length! > 1 ? ` (+${selectedVisit?.visitors.length! - 1})` : ''),
                            status: getStatusNameById(selectedVisit?.visitStatus.id!, selectedVisit?.scheduleStart),
                            requested: dayjs(selectedLog.requestedUtc).utc(true).local().format('YYYY-MM-DD h:mm A'),
                            entry: getEntryName(selectedLog.entryId),
                            ipAddress: selectedLog.ipAddress,
                            result: selectedLog.success ? 'Success' : 'Failed',
                        }}
                        defaultButtons={false}
                        hasError={false}
                        onSubmit={function (args: IOnSubmitArgs<Store>): void {
                            throw new Error('Function not implemented.');
                        }}
                    >
                        <OpRow gutter={16}>
                            <OpCol span={12}>
                                <OpForm.Input label="Visitor" name="visitor" readOnly />
                            </OpCol>
                            <OpCol span={12}>
                                <OpForm.Input
                                    label="Status"
                                    name="status"
                                    readOnly
                                    style={{
                                        backgroundColor: getStatusColor(getStatusNameById(selectedVisit?.visitStatus.id!, selectedVisit?.scheduleStart)),
                                        color: 'white',
                                    }}
                                />
                            </OpCol>
                        </OpRow>
                        <OpRow gutter={16}>
                            <OpCol span={12}>
                                <OpForm.Input label="Requested" name="requested" readOnly />
                            </OpCol>
                            <OpCol span={12}>
                                <OpForm.Input label="Entry" name="entry" readOnly />
                            </OpCol>
                        </OpRow>
                        <OpRow gutter={16}>
                            <OpCol span={12}>
                                <OpForm.Input label="Source IP" name="ipAddress" readOnly />
                            </OpCol>
                            <OpCol span={12}>
                                <OpForm.Input
                                    label="Result"
                                    name="result"
                                    readOnly
                                    style={{
                                        color: selectedLog.success ? 'green' : 'var(--colorError)',
                                    }}
                                />
                            </OpCol>
                        </OpRow>
                    </OpForm>
                </OpModal>
            )}
        </>
    );
};

export default GuestPassLogTab;
