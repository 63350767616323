import React from 'react';
import { Modal, notification } from 'antd';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT, TIME_AM_PM_FORMAT } from 'constants/dates';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import STATUS from 'constants/status';
import { patchRequest } from 'api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchVisits } from 'store/slices/visitsSlice';

interface ProfileEditModalProps {
    open: boolean;
    onClose: () => void;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisit, selectedVisitor } = useSelector((state: RootState) => state.visits);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const [form] = OpForm.useForm();

    const initialValues = {
        ...selectedVisitor,
        signIn: selectedVisitor?.signIn ? dayjs(selectedVisitor.signIn) : null,
        signOut: selectedVisitor?.signOut ? dayjs(selectedVisitor.signOut) : null,
    }

    const editVisitor = async (updatedVisitor: any) => {
        try {
            const payload = {
                ...(updatedVisitor.firstName != null && { firstName: (updatedVisitor.firstName).trim() }),
                ...(updatedVisitor.middleName != null && { middleName: (updatedVisitor.middleName).trim() }),
                ...(updatedVisitor.lastName != null && { lastName: (updatedVisitor.lastName).trim() }),
                ...(updatedVisitor.email != null && { email: updatedVisitor.email }),
                ...(updatedVisitor.mobilePhone != null && { mobilePhone: updatedVisitor.mobilePhone }),
                ...(updatedVisitor.signIn != null && { signIn: dayjs(updatedVisitor.signIn).format(DATE_TIME_FORMAT) }),
                ...(updatedVisitor.signOut != null && { signOut: dayjs(updatedVisitor.signOut).format(DATE_TIME_FORMAT) }),
            };

            if (payload.signOut && payload.signIn && dayjs(payload.signOut).isBefore(dayjs(payload.signIn))) {
                notification.error({
                    message: 'Error',
                    description: 'End date cannot be before start date.',
                    placement: 'bottomRight',
                });
                onClose();
                return;
            }

            try {
                await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitors/${selectedVisitor?.id}`, payload);
                await dispatch(fetchVisits({ orgId }));
                onClose();
                notification.success({
                    message: 'Success',
                    description: 'Profile updated successfully.',
                    placement: 'bottomRight',
                });
            } catch (error) {
                console.error('Failed to update visitor:', error);
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to update visitor.',
                placement: 'bottomRight',
            });
        }
    };

    return (
        <Modal
            open={open}
            title={'Edit Visitor'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.submit();
                        onClose();
                    })
                    .catch((errorInfo) => {
                        notification.error({
                            message: 'Validation Error',
                            description: 'Please fill in all required fields.',
                            placement: 'bottomRight',
                        });
                    });
            }}
            centered
        >
            <OpForm
                form={form}
                initialValues={initialValues}
                onSubmit={({ values }) => {
                    editVisitor(values);
                    form.resetFields();
                }}
                defaultButtons={false}
                hasError={false}
            >
                {(selectedVisitor?.status === STATUS.PENDING.id) && (
                    <>
                        <OpForm.Input
                            label="First Name" name="firstName"
                            rules={[{ required: true, message: 'Please enter first name.' }]}
                        />
                        <OpForm.Input
                            label="Last Name" name="lastName"
                            rules={[{ required: true, message: 'Please enter last name.' }]}
                        />
                        <OpForm.Input label="Middle Name" name="middleName" />
                        <OpForm.Input label="Email" name="email"
                            rules={[
                                {
                                    required: (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.email.required === 1)
                                }
                            ]}
                        />
                        <OpForm.Input label="Mobile Phone" name="mobilePhone"
                            rules={[
                                {
                                    required: (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.visitPhone.required === 1)
                                }
                            ]}
                        />
                    </>
                )}
                {(selectedVisitor?.status === STATUS.SIGNED_IN.id) && (
                    <OpForm.TimePicker format={TIME_AM_PM_FORMAT}
                        name="signIn"
                        label="Sign In"
                        minuteStep={5}
                        rules={[{ required: true, message: 'Please select the sign in time.' }]}
                    />
                )}
                {(selectedVisitor?.status === STATUS.SIGNED_OUT.id) && (
                    <>
                        <OpForm.TimePicker format={TIME_AM_PM_FORMAT}
                            name="signIn"
                            label="Sign In"
                            minuteStep={5}
                            rules={[{ required: true, message: 'Please select the sign in time.' }]}
                        />
                        <OpForm.TimePicker format={TIME_AM_PM_FORMAT}
                            name="signOut"
                            label="Sign Out"
                            minuteStep={5}
                            rules={[{ required: true, message: 'Please select the sign out time.' }]}
                        />
                    </>
                )}
            </OpForm>
        </Modal>
    );
};

export default ProfileEditModal;
