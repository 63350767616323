import React from 'react';
import { Visitor } from 'types/visitTypes';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { notification } from 'antd';

interface ModalFormProps {
    open: boolean;
    isInviting: boolean;
    visitor?: any | null;
    onOk: (data: Visitor) => void;
    onClose: () => void;
}

const VisitorListModal: React.FC<ModalFormProps> = ({ open, isInviting, visitor, onOk, onClose }) => {
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const { globalSignInWorkflowFields } = useSelector((state: RootState) => state.visitorWorkflows);

    const [form] = OpForm.useForm();

    return (
        <OpModal
            open={open}
            title={visitor ? 'Edit Visitor' : 'Add Visitor'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.submit();
                        onClose();
                    })
                    .catch((errorInfo) => {
                        notification.error({
                            message: 'Validation Error',
                            description: 'Please fill in all required fields.',
                            placement: 'bottomRight',
                        });
                    });
            }}
            centered
        >
            <OpForm
                form={form}
                onSubmit={({ values }) => {
                    onOk(values);
                    form.resetFields();
                }}
                hasError={false}
                defaultButtons={false}
                initialValues={visitor ? visitor : undefined}
            >
                <OpForm.Input
                    label="First Name" name="firstName"
                    rules={[{ required: true, message: 'Please enter first name.' }]}
                />
                <OpForm.Input
                    label="Last Name" name="lastName"
                    rules={[{ required: true, message: 'Please enter last name.' }]}
                />
                {(!isInviting && globalSignInWorkflowFields?.middleName.included === 1) ? (
                    <OpForm.Input
                        label="Middle Name" name="middleName"
                        rules={[{ required: (!isInviting && globalSignInWorkflowFields.middleName.required === 1), message: 'Please enter middle name.' }]}
                    />
                ) : undefined}
                {((isInviting && invitationConfig?.email.included === 1) || (!isInviting && globalSignInWorkflowFields?.email.included === 1)) ? (
                    <OpForm.Input
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: (isInviting && invitationConfig?.email.required === 1) ||
                                    (!isInviting && globalSignInWorkflowFields?.email.required === 1),
                                message: 'Please enter email.'
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address.'
                            }
                        ]}
                    />
                ) : undefined}
                {((isInviting && invitationConfig?.visitPhone.included === 1) || (!isInviting && globalSignInWorkflowFields?.visitPhone.included === 1)) ? (
                    <OpForm.Input
                        label="Mobile Phone" name="mobilePhone"
                        rules={[{ required: (isInviting && invitationConfig?.visitPhone.required === 1) || (!isInviting && globalSignInWorkflowFields?.visitPhone.required === 1), message: 'Please enter mobile phone.' }]}
                    />
                ) : undefined}
            </OpForm>
        </OpModal>
    );
};

export default VisitorListModal;
