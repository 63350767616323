import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Alert, InputRef, notification } from 'antd';
import { clearError } from 'store/slices/authSlice';
import { AppDispatch } from 'store/store';
import { useDispatch } from 'react-redux';

interface AvigilonAltaLoginProps {
    onFinish: (values: { email: string; password: string; code?: string }) => void;
    onBack: () => void;
    error: string | null;
}

const AvigilonAltaLogin: React.FC<AvigilonAltaLoginProps> = ({ onBack, onFinish, error }) => {
    const dispatch: AppDispatch = useDispatch();

    const [showCodeInput, setShowCodeInput] = useState(false);

    const emailInputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (error) {
            notification.error({
                message: 'Login Error',
                description: 'Credentials are incorrect.',
                placement: 'bottomRight',
            });
            dispatch(clearError());
        }
    }, [dispatch, error]);

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: '400px', margin: '0 auto' }}
        >
            <Alert
                message="Note: Avigilon Alta Access account and InVisit account for your organization must be already connected by your administrators."
                type="info"
                showIcon
                style={{ marginBottom: '24px' }}
            />
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    { required: true, message: 'Email is required' },
                    { type: 'email', message: 'Please enter a valid email address' },
                ]}
            >
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Password is required' }]}
                style={showCodeInput ? {} : { marginBottom: '8px' }}
            >
                <Input.Password autoComplete="off" />
            </Form.Item>

            {!showCodeInput && (
                <Button
                    type="link"
                    onClick={() => setShowCodeInput(true)}
                    style={{ padding: '0px', marginBottom: '8px' }}
                >
                    Enter two-factor authentication code
                </Button>
            )}

            {showCodeInput && (
                <Form.Item
                    name="code"
                    label="Enter two-factor authentication code"
                    rules={[{ required: true, message: 'Authentication code is required' }]}
                >
                    <Input autoComplete="off" />
                </Form.Item>
            )}

            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    Sign in
                </Button>
            </Form.Item>
            <Button
                onClick={onBack}
                type="link"
                className="button"
                style={{ fontWeight: 'bold' }}
            >
                {'Return to sign in'}
            </Button>
        </Form>
    );
};

export default AvigilonAltaLogin;