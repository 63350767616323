import React from 'react';
import dayjs from 'dayjs';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';

// Import constants
import { DATE_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName } from 'utils/utils';
import { RedFlag } from 'types/redFlagTypes';

interface RedFlagsTableData {
    redFlagId: number;
    fullName: string;
    date: string;
}

interface RedFlagsTableProps {
    redFlags: RedFlag[];
    handleEditClick: (redFlag: RedFlag) => void;
    isBlockList: boolean;
}

const RedFlagsTable: React.FC<RedFlagsTableProps> = ({ redFlags, handleEditClick, isBlockList }) => {
    // Format red flags for table display
    const formatRedFlagsForTable = (redFlags: RedFlag[]): RedFlagsTableData[] => {
        return redFlags
            .map((redFlag) => {
                const date = dayjs(redFlag.effectiveDate).utc(true).local().format(DATE_FORMAT);
                return {
                    redFlagId: redFlag.id,
                    fullName: formatFullName(redFlag.firstName, redFlag.middleName, redFlag.lastName),
                    date: date,
                    ...redFlag,
                };
            });
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        },
        {
            dataIndex: 'date',
            label: "Date & Time",
            sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formatRedFlagsForTable(redFlags),
        label: isBlockList ? "Added to Blocklist" : "Added to Watchlist",
        columns: columns,
        rowActions: {
            onEditClick: handleEditClick,
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowExport: true,
        allowShowHideColumns: true,
        rowKey: 'redFlagId',
    };

    return <OpTable {...opTableProps} />;
}

export default RedFlagsTable;
