import React, { useState, useEffect } from "react";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpTabs } from "components/customAntd/DLS/OpTabs/OpTabs";
import { DRAWER_WIDTH } from "constants/ui";
import ActivityLogContent from "./tabs/ActivityLogFormContent";
import { UserActivity } from "types/userActivityTypes";
import { OpFormDrawer } from "components/customAntd/DLS/OpFormDrawer/OpFormDrawer";
import dayjs from 'dayjs';

interface ActivityLogDrawerProps {
    open: boolean;
    selectedUserActivitiy: UserActivity | null;
    onClose: () => void;
}

const ActivityLogDrawer: React.FC<ActivityLogDrawerProps> = ({ open, selectedUserActivitiy, onClose }) => {
    const [activeKey, setActiveKey] = useState<string>('activityLog');

    const [form] = OpForm.useForm();

    useEffect(() => {
        if (open) {
            setActiveKey('activityLog');
        }
    }, [open, form]);

    const initialValues = {
        activity: selectedUserActivitiy?.activityType,
        user: selectedUserActivitiy?.user,
        date: dayjs(selectedUserActivitiy?.updatedAt).startOf('day'),
        time: dayjs(selectedUserActivitiy?.updatedAt).local(),
        activityDetails: selectedUserActivitiy?.activityDetails
    };

    const tabItems = [
        {
            key: 'activityLog',
            label: 'Activity Log',
            children: <ActivityLogContent />,
        },
    ];


    return (
        <OpFormDrawer
            form={form}
            title={'Activity Log'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormReadOnly={true}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={() => console.log()}
                    isReadOnly={true}
                    hasError={false}
                    defaultButtons={false}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
};

export default ActivityLogDrawer;
